import { SecondaryButton } from 'design-system/components/Button/presets';
import Dialog from 'design-system/components/Dialog/Dialog';
import { useSelector } from 'reffects-store';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import { BOOSTING_LIMIT_REACHED_MODAL } from '../events';
import boostingLimitReachedTutorialImage from './boostingLimitReachedTutorial2.jpg';
import { useDialog } from '../../../../hooks/useDialog';
import { supportEmailSelector } from '../../../../selectors/config';
import Offers from '../partials/Offers';
import { getPublisherMaxBoostablePropertiesFromSubscriptionSelector } from '../../../../selectors/publisher';
import { upsellOffersSelector } from '../../../Offers/selectors';

function ContentWithoutOffers() {
  const supportEmail = useSelector(supportEmailSelector);
  return (
    <Dialog.Content>
      <Dialog.Text>
        <FormattedMessageWithValidation
          id="boosting_limit_reached_modal_text"
          values={{
            supportEmail: <a href={`mailto:${supportEmail}`}>{supportEmail}</a>,
          }}
        />
      </Dialog.Text>
      <img
        src={boostingLimitReachedTutorialImage}
        alt="Boosting limit reached tutorial"
      />
    </Dialog.Content>
  );
}

function ContentWithOffers({ offers, section }) {
  const maxBoostableProperties = useSelector(
    getPublisherMaxBoostablePropertiesFromSubscriptionSelector
  );
  return (
    <Dialog.Content>
      <Dialog.Text>
        <FormattedMessageWithValidation
          id="boosting_limit_reached_modal_notice"
          values={{ maxBoostableProperties }}
        />
      </Dialog.Text>
      <Offers offers={offers} offerType="Upsell" section={section} />
    </Dialog.Content>
  );
}

export function BoostingLimitReachedModal() {
  const { open, closeDialog, parameters } = useDialog(
    BOOSTING_LIMIT_REACHED_MODAL
  );
  const offers = useSelector(upsellOffersSelector);
  const showOffers = offers.length > 0;
  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation id="boosting_limit_reached_modal_title" />
      </Dialog.Header>
      {!showOffers && (
        <Dialog.Emphasis>
          <FormattedMessageWithValidation id="boosting_limit_reached_modal_message" />
        </Dialog.Emphasis>
      )}
      {open &&
        (showOffers ? (
          <ContentWithOffers offers={offers} section={parameters.section} />
        ) : (
          <ContentWithoutOffers />
        ))}
      <Dialog.Footer>
        <SecondaryButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="freelistings_button_continue" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}
