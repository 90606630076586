import { INVOICES_TAB } from './partials/BillingSwitch/events';
import {
  hasSubscriptionChangeRequestForSuperboostSelector,
  publisherCountrySelector,
  publisherHasSuperboostSubscriptionSelector,
  publisherSubscriptionPlanIsFreeSelector,
} from '../../selectors/publisher';
import { publishedPropertiesSelector } from '../../selectors/propertiesSummary';
import { COUNTRY_CODE } from '../../constants/country';
import { isArgentina } from '../../utils/countries';

export function subscriptionPageShowInvoicesTabSelector(state) {
  return state['subscriptionPage:showTab'] === INVOICES_TAB;
}

export function showSuperboostBannerSelector(state) {
  return (
    publisherHasSuperboostSubscriptionSelector(state) ||
    hasSubscriptionChangeRequestForSuperboostSelector(state)
  );
}

export function pendingSCRsSelector(state) {
  return state.publisher?.pendingSCRs ?? [];
}

export function hasCancelRequestSelector(state) {
  return ['pending', 'accepted'].includes(
    autoRenewalCancellationStatusSelector(state)
  );
}

export function autoRenewalCancellationStatusSelector(state) {
  return state.requestAutoRenewalCancellation?.status;
}

function showOffersEnabledByCountry(state) {
  const availableCountries = [
    COUNTRY_CODE.MEXICO,
    COUNTRY_CODE.ARGENTINA,
    COUNTRY_CODE.PERU,
    COUNTRY_CODE.CHILE,
    COUNTRY_CODE.COLOMBIA,
    COUNTRY_CODE.INDONESIA,
  ];
  const country = publisherCountrySelector(state);
  return availableCountries.includes(country);
}

export function shouldShowOffersSelector(state) {
  if (isArgentina(publisherCountrySelector(state))) {
    return publisherSubscriptionPlanIsFreeSelector(state);
  }
  return (
    showOffersEnabledByCountry(state) &&
    publisherSubscriptionPlanIsFreeSelector(state) &&
    publishedPropertiesSelector(state) <= 10
  );
}
