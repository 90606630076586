import { coeffect, registerCoeffectHandler } from 'reffects';

const COEFFECT_NAME = 'environment';

export default function registerEnvironmentCoeffect(variables) {
  registerCoeffectHandler(COEFFECT_NAME, () => ({
    [COEFFECT_NAME]: {
      apiUrl: buildApiUrl(variables.ORIGIN, variables.API_PORT),
      paymentsApiUrl: variables.PAYMENTS_API_URL,
      chatTrackingApiUrl: variables.CHAT_TRACKING_API_URL,
      devFlags: {
        disableLoggedPublisherChecking:
          variables.DISABLE_LOGGED_PUBLISHER_CHECKING === 'true',
      },
      version: variables.VERSION,
    },
  }));
}

export function environment() {
  return coeffect(COEFFECT_NAME);
}

export function buildApiUrl(origin, apiPort) {
  const url = new URL(origin);
  const port = apiPort ? `:${apiPort}` : '';
  return `${url.protocol}//api.${url.host}${port}`;
}
