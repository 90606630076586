export const LOADING_ADS_MODE = {
  CRM: 'CRM',
  XML: 'XML',
  MANUAL: 'manual',
};

export const MEXICO_TYPE_OF_CLIENT = {
  REMATES: 'remates',
  REMATES_PREMIUM: 'remates-premium',
  AGENCIA: 'agencia',
  AGENCIA_PREMIUM: 'agencia-premium',
};

export const TYPE_OF_PLANS = {
  PROFESSIONAL: 'professional',
  PREMIUM: 'premium',
};

export const CREATION_STEP = {
  FORM: 'creation-form',
  PROJECTS: 'assign-projects',
};

export const CREATE_PUBLISHER_PAGE = 'publisherCreationForm';

export const INVALID_UUID_ERROR = `${CREATE_PUBLISHER_PAGE}.errorInvalidUUID`;
export const MANDATORY_PUBLISHER_MAPPINGS_ERROR = `${CREATE_PUBLISHER_PAGE}.errorPublisherMappingMandatory`;

export const MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE = {
  NO: 'no',
  MAIN: 'main',
  SHARED: 'shared',
  OWNED: 'owned',
};
