export function eligibleLeadsForLeadPlusSelector(state) {
  return state.leadPlusEligible ?? {};
}

export function eligibleLeadsForQualificationSelector(state) {
  return state.qualificationEligible ?? {};
}

export function eligibleLeadsForHotnessSelector(state) {
  return state.hotnessEligible ?? {};
}
