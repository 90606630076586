import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { useSelector } from 'reffects-store';
import { RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS } from '../../events';
import CancellationRequestStatusBanner from '../CancellationRequestStatusBanner';
import {
  autoRenewalCancellationStatusSelector,
  hasCancelRequestSelector,
  pendingSCRsSelector,
  showSuperboostBannerSelector,
} from '../../selectors';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import CurrentPlanBanner from '../CurrentPlanBanner';
import PendingPlanChangeBanner from '../PendingPlanChangeBanner';
import ContractedSuperboostBanner from '../SubscriptionBanners/ContractedSuperboostBanner';
import PendingSuperboostBanner from '../SubscriptionBanners/PendingSuperboostBanner';
import { SubscriptionComparingTable } from '../SubscriptionTable';
import {
  changedBillingCycleInTHSelector,
  publisherSubscriptionPlanIsPaidSelector,
} from '../../../../selectors/publisher';

const SubscriptionSection = styled.div`
  ${spacing.stack(SPACING_SIZE.XL)}
  > ${Subtitle1} {
    display: block;
    ${spacing.stack(SPACING_SIZE.M)}
  }

  ${CancellationRequestStatusBanner} {
    ${spacing.stack(SPACING_SIZE.L, false)}
  }
`;

export default function SubscriptionTab() {
  const hasPaidSubscription = useSelector(
    publisherSubscriptionPlanIsPaidSelector
  );
  const showSuperboostBanner = useSelector(showSuperboostBannerSelector);
  const changedBillingCycleInTH = useSelector(changedBillingCycleInTHSelector);
  const pendingSCRs = useSelector(pendingSCRsSelector);
  const hasCancelRequest = useSelector(hasCancelRequestSelector);
  const cancellationStatus = useSelector(autoRenewalCancellationStatusSelector);

  useMount(() =>
    dispatch({
      id: RETRIEVE_AUTO_RENEWAL_CANCELLATION_STATUS,
      payload: { hasPaidSubscription },
    })
  );

  return hasPaidSubscription ? (
    <>
      <SubscriptionSection>
        <Subtitle1>
          <FormattedMessageWithValidation id="subscription_section_plan_details" />
        </Subtitle1>
        <div>
          {hasCancelRequest && (
            <CancellationRequestStatusBanner status={cancellationStatus} />
          )}
          {!changedBillingCycleInTH && <CurrentPlanBanner />}
          {pendingSCRs.map((pendingSCR) => (
            <PendingPlanChangeBanner scr={pendingSCR} />
          ))}
        </div>
      </SubscriptionSection>
      <SubscriptionSection>
        {showSuperboostBanner && (
          <>
            <Subtitle1>
              <FormattedMessageWithValidation id="subscription_section_extras" />
            </Subtitle1>
            <div>
              <ContractedSuperboostBanner />
              {pendingSCRs.map((pendingSCR) => (
                <PendingSuperboostBanner scr={pendingSCR} />
              ))}
            </div>
          </>
        )}
      </SubscriptionSection>
    </>
  ) : (
    <SubscriptionComparingTable />
  );
}
