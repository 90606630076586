import { MEXICO_TYPE_OF_CLIENT } from '../pages/CreatePublisher/constants';

export function normalizeTypeOfClient(typeOfClient) {
  if (typeOfClient === MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM) {
    return MEXICO_TYPE_OF_CLIENT.REMATES;
  }
  if (typeOfClient === MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM) {
    return MEXICO_TYPE_OF_CLIENT.AGENCIA;
  }
  return typeOfClient;
}
