import styled from 'styled-components';
import { Overline } from 'design-system/components/Typography/presets/Overline';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';

const Wrapper = styled(Overline)`
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)};
  ${color.background(COLOR_PALETTE.PRIMARY_BASE)};
  padding: 0 ${spacing.value(SPACING_SIZE.S)};
  ${radius.regular(RADIUS_SIZE.LARGE)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function UnreadCount({ numberOfUnreadElements = 1 }) {
  return <Wrapper>{numberOfUnreadElements}</Wrapper>;
}
