import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../events/dialogs';
import {
  AUTO_RENEWAL_CANCELLATION_REQUESTED_MODAL,
  REQUEST_AUTO_RENEWAL_CANCELLATION_MODAL,
} from './constants';
import { environment } from '../../../../coeffects/environment';

export const OPEN_REQUEST_CANCELLATION_MODAL =
  'OPEN_REQUEST_CANCELLATION_MODAL';
export const CANCELLATION_REASON_CHANGED = 'CANCELLATION_REASON_CHANGED';
export const REQUEST_AUTO_RENEWAL_CANCELLATION =
  'REQUEST_AUTO_RENEWAL_CANCELLATION';
export const REQUEST_AUTO_RENEWAL_CANCELLATION_SUCCESS =
  'REQUEST_AUTO_RENEWAL_CANCELLATION_SUCCESS';
export const REQUEST_AUTO_RENEWAL_CANCELLATION_FAILED =
  'REQUEST_AUTO_RENEWAL_CANCELLATION_FAILED';

registerEventHandler(OPEN_REQUEST_CANCELLATION_MODAL, () => ({
  ...state.set({ requestAutoRenewalCancellation: undefined }),
  ...effects.dispatch({
    id: OPEN_DIALOG,
    payload: { id: REQUEST_AUTO_RENEWAL_CANCELLATION_MODAL },
  }),
}));

registerEventHandler(CANCELLATION_REASON_CHANGED, (_, { reason }) =>
  state.set({
    requestAutoRenewalCancellation: { reason },
  })
);

registerEventHandler(
  REQUEST_AUTO_RENEWAL_CANCELLATION,
  ({ environment: { apiUrl } }, { reason }) => ({
    ...state.set({ 'requestAutoRenewalCancellation.submitted': true }),
    ...http.post({
      url: `${apiUrl}/subscriptions/me/cancel-request`,
      body: { reason },
      successEvent: REQUEST_AUTO_RENEWAL_CANCELLATION_SUCCESS,
      errorEvent: REQUEST_AUTO_RENEWAL_CANCELLATION_FAILED,
    }),
  }),
  [environment()]
);

registerEventHandler(REQUEST_AUTO_RENEWAL_CANCELLATION_SUCCESS, () => ({
  ...effects.dispatchMany([
    CLOSE_DIALOG,
    {
      id: OPEN_DIALOG,
      payload: { id: AUTO_RENEWAL_CANCELLATION_REQUESTED_MODAL },
    },
  ]),
  ...state.set({
    requestAutoRenewalCancellation: { status: 'pending', submitted: false },
  }),
}));

registerEventHandler(REQUEST_AUTO_RENEWAL_CANCELLATION_FAILED, () =>
  state.set({ 'requestAutoRenewalCancellation.submitted': false })
);
