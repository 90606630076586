import { TYPE_OF_CLIENT_FREE_VALUE } from './constants';
import InputSelectWithHtmlValidation from '../InputSelectWithHtmlValidation';
import filterChoices from './filterChoices';
import { useFeatureSelector } from '../../../../utils/featuresFlags/selectors';

const propertyName = 'typeOfClient';

const defaultValue = TYPE_OF_CLIENT_FREE_VALUE;

function TypeOfClientSelect({ value, availableTypes, onChange }) {
  const PREMIUM_PLAN_4233 = useFeatureSelector('PREMIUM_PLAN_4233');

  return (
    <InputSelectWithHtmlValidation
      name={propertyName}
      label="Type of client"
      value={value}
      required
      choices={filterChoices(availableTypes, PREMIUM_PLAN_4233)}
      onSelect={onChange}
    />
  );
}

TypeOfClientSelect.propertyName = propertyName;
TypeOfClientSelect.defaultValue = defaultValue;

export default TypeOfClientSelect;
