import { userIsPublisherCollaboratorSelector } from '../../selectors/user';

export function newContractOffersSelector(state) {
  if (userIsPublisherCollaboratorSelector(state)) {
    return [];
  }
  return state.offers ?? [];
}

export function upsellOffersSelector(state) {
  if (userIsPublisherCollaboratorSelector(state)) {
    return [];
  }
  return state.upsellOffers ?? [];
}
