import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const OfferDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XS)};
`;

export default OfferDetails;
