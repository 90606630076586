import { registerEffectHandler } from 'reffects';
import noop from '../utils/noop';
import { isPublisherForTestingSelector } from '../selectors/publisher';
import { isBackofficeUserSelector } from '../selectors/user';

export default function registerTrackingEffects(
  client,
  apiUrl,
  store,
  globalObject = window,
  disableTracking = false,
  log = console.log
) {
  registerEffectHandler('tracking.track', ({ name, section, payload }) => {
    const state = store.getState();
    if (
      shouldNotTrack(state, disableTracking, log, { name, section, payload })
    ) {
      return;
    }

    const { user, publisher, device } = state;
    client.post({
      url: `${apiUrl}/track`,
      body: {
        name: `${name}`,
        section,
        commonTrackingInfo: {
          userId: user?.id,
          publisherId: publisher?.id,
          subscriptionId: publisher?.subscriptionId,
          countryCode: publisher?.countryCode,
          device: device?.type,
          userAgent: globalObject.navigator.userAgent,
          referrer: globalObject.referrer,
        },
        ...payload,
      },
      successFn: noop,
      errorFn: noop,
    });
  });
}

function shouldNotTrack(state, disableTracking, log, params) {
  if (disableTracking) {
    log(
      'Not tracked because tracking is disabled in current environment.',
      params
    );
    return true;
  }
  const isPublisherForTesting = isPublisherForTestingSelector(state);

  if (isPublisherForTesting) {
    log('Not tracked because the publisher is for testing.', params);
    return true;
  }

  const isBackofficeUser = isBackofficeUserSelector(state);

  if (isBackofficeUser) {
    log('Not tracked because the user is from backoffice.', params);
    return true;
  }

  return false;
}

export const tracking = {
  track(name, section, payload = {}) {
    return {
      'tracking.track': { name, section, payload },
    };
  },
};
