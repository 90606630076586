import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const OfferHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.S)};
  padding-bottom: ${spacing.value(SPACING_SIZE.M)};
`;

export default OfferHeading;
