import styled, { css } from 'styled-components';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { useSelector } from 'reffects-store';
import { Subtitle3 } from 'design-system/components/Typography/presets/Subtitle3';
import Anchor from 'design-system/components/Anchor';
import { PrimaryButton } from 'design-system/components/Button/presets';
import { dispatch } from 'reffects';
import Icon from 'design-system/components/Icon';
import { BUTTON_ICON_POSITION } from 'design-system/components/Button';
import {
  FONT_FAMILY,
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { useState } from 'react';
import Checkbox from 'design-system/components/Checkbox/Checkbox';
import Loader from 'design-system/components/Loader';
import FormattedMessageWithValidation from '../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import CreditCardIframe from './CreditCardIframe';
import {
  creditCardSelector,
  hasCreditCardSavedSelector,
  isLoadingSavedCardSelector,
} from '../../../../../selectors/payment';
import { SavedBankCard } from '../../../../../partials/SavedBankCard';
import {
  PAY_WITH_SAVED_CARD,
  TOGGLE_TERMS_AND_CONDITIONS_ACCEPTANCE,
} from './events';
import { termsAndConditionsAcceptedSelector } from './selectors';
import { legalLinksSelector } from '../../../../../selectors/config';
import { isUserLoggedInSelector } from '../../../../../App/selectors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${spacing.value(SPACING_SIZE.XL)};
`;

const SavedCardBlock = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #ccc;
`;

const RecurrentPaymentTip = styled.div`
  ${(props) =>
    !props.theme?.isMobile &&
    css`
      ${spacing.stack(SPACING_SIZE.M)}
    `}

  ${color.background(COLOR_PALETTE.PRIMARY_A10)}
  ${spacing.inset(SPACING_SIZE.S)}
  ${radius.regular(RADIUS_SIZE.BASE)}
`;

const CtaButton = styled(PrimaryButton)`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
  ${typography.font(FONT_FAMILY.PRIMARY)}
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  border-radius: 6px;
`;
const TermsAndConditions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.M)};
  ${typography.size(FONT_SIZE.S)}
  ${typography.weight(FONT_WEIGHT.NORMAL)}
  ${typography.lineHeight(20)}
  ${typography.font(FONT_FAMILY.PRIMARY)}

  ${Anchor} {
    ${typography.size(FONT_SIZE.S)}
    ${typography.weight(FONT_WEIGHT.MEDIUM)}
  }

  label {
    cursor: pointer;
  }
`;
const PaymentContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;
const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 17px;
  ${Loader} {
    width: 17px;
    height: 17px;
  }
`;

function UseNewCard({ subscriptionChangeRequestId }) {
  return (
    <>
      <RecurrentPaymentTip>
        <Body1 $color={COLOR_PALETTE.NEUTRAL_A80}>
          <FormattedMessageWithValidation id="checkout_bankcard_recurrentText" />
        </Body1>
      </RecurrentPaymentTip>
      <CreditCardIframe
        subscriptionChangeRequestId={subscriptionChangeRequestId}
      />
    </>
  );
}

export function CreditCard({ subscriptionChangeRequestId }) {
  const [showNewCardForm, setShowNewCardForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingSaveCard = useSelector(isLoadingSavedCardSelector);
  const hasSavedCard = useSelector(hasCreditCardSavedSelector);
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);
  const savedCard = useSelector(creditCardSelector);
  const termsAndConditionsAccepted = useSelector(
    termsAndConditionsAcceptedSelector
  );
  const { termsAndConditions, privacyPolicy } = useSelector(legalLinksSelector);

  if (isLoadingSaveCard) {
    return null;
  }
  if (showNewCardForm || !hasSavedCard) {
    return (
      <UseNewCard subscriptionChangeRequestId={subscriptionChangeRequestId} />
    );
  }
  const handleButtonClick = () => {
    setIsLoading(true);
    dispatch({
      id: PAY_WITH_SAVED_CARD,
      payload: {
        subscriptionChangeRequestId,
      },
    });
  };
  return (
    <Wrapper>
      <Subtitle3>
        <FormattedMessageWithValidation id="checkout_saved_card_title" />
      </Subtitle3>
      <PaymentContent>
        <SavedCardBlock>
          <SavedBankCard
            cardType={savedCard.creditCardType}
            tokenizedCardNumber={savedCard.lastDigits}
          />
        </SavedCardBlock>
        <Anchor
          onClick={() => {
            if (isUserLoggedIn) {
              setShowNewCardForm(true);
            } else {
              dispatch({ id: 'REDIRECTED_TO_LOGIN' });
            }
          }}
        >
          <FormattedMessageWithValidation id="subscription_payment_creditCard_changeCard" />
        </Anchor>
      </PaymentContent>
      <TermsAndConditions>
        <Checkbox
          id="acceptTermsAndConditions"
          checked={termsAndConditionsAccepted}
          onChange={(e) =>
            dispatch({
              id: TOGGLE_TERMS_AND_CONDITIONS_ACCEPTANCE,
              payload: e.target.checked,
            })
          }
        />
        <label htmlFor="acceptTermsAndConditions">
          <FormattedMessageWithValidation
            id="checkout_terms_agreement"
            values={{
              privacyPolicy: (
                <Anchor href={privacyPolicy}>
                  <FormattedMessageWithValidation id="checkout_terms_agreement_privacy" />
                </Anchor>
              ),
              termsConditions: (
                <Anchor href={termsAndConditions}>
                  <FormattedMessageWithValidation id="checkout_terms_agreement_terms" />
                </Anchor>
              ),
            }}
          />
        </label>
      </TermsAndConditions>
      <CtaButton
        onClick={handleButtonClick}
        icon={isLoading ? '' : <Icon glyph="path" />}
        $iconPosition={BUTTON_ICON_POSITION.START}
        disabled={isLoading || !termsAndConditionsAccepted}
      >
        {isLoading && (
          <LoaderWrapper>
            <Loader />
          </LoaderWrapper>
        )}
        {!isLoading && (
          <FormattedMessageWithValidation id="checkout_tab2_cta_next" />
        )}
      </CtaButton>
    </Wrapper>
  );
}
