import { registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { http } from 'reffects-batteries';
import { environment } from '../../../../coeffects/environment';

export const UPSELL_OFFERS_MOUNTED = 'UPSELL_OFFERS_MOUNTED';
export const UPSELL_OFFERS_LOADED = 'UPSELL_OFFERS_LOADED';
export const UPSELL_OFFERS_UNMOUNTED = 'UPSELL_OFFERS_UNMOUNTED';

registerEventHandler(
  UPSELL_OFFERS_MOUNTED,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/self-service/plans/upsell`,
      successEvent: UPSELL_OFFERS_LOADED,
    }),
  [environment()]
);

registerEventHandler(UPSELL_OFFERS_LOADED, (_, [{ data }]) =>
  state.set({ upsellOffers: data })
);

registerEventHandler(UPSELL_OFFERS_UNMOUNTED, () =>
  state.set({ upsellOffers: null })
);
