import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { CLOSE_DIALOG, OPEN_DIALOG } from '../../../../../../../events/dialogs';
import { CHANGE_SUBSCRIPTION_MODAL } from './constants';
import { environment } from '../../../../../../../coeffects/environment';
import { toast } from '../../../../../../../effects/toast';
import { convertPublisherToSelectedOptions } from '../convertPublisherToSelectedOptions';
import { isMexico } from '../../../../../../../utils/countries';
import findPlanByTypeOfClientAndMaxBoostableProperties, {
  findPremiumPlanByTypeOfClientAndMaxBoostableProperties,
} from '../../../../../../utils/findPlanByTypeOfClientAndMaxBoostableProperties';
import { PLANS_STATE_REGION } from '../../../../../constants';
import { featureFlags } from '../../../../../../../coeffects/featureFlags';
import { MEXICO_TYPE_OF_CLIENT } from '../../../../../CreatePublisher/constants';

export const CHANGE_SUBSCRIPTION_ACTION_REQUESTED =
  'CHANGE_SUBSCRIPTION_ACTION_REQUESTED';
export const CHANGE_SUBSCRIPTION_PUBLISHER_DATA_LOAD_SUCCEEDED =
  'CHANGE_SUBSCRIPTION_PUBLISHER_DATA_LOAD_SUCCEEDED';
export const CHANGE_SUBSCRIPTION_PUBLISHER_DATA_LOAD_FAILED =
  'CHANGE_SUBSCRIPTION_PUBLISHER_DATA_LOAD_FAILED';
export const CHANGE_SUBSCRIPTION_VALUE_CHANGED =
  'CHANGE_SUBSCRIPTION_VALUE_CHANGED';
export const SUBSCRIPTION_CHANGES_CONFIRMED = 'SUBSCRIPTION_CHANGES_CONFIRMED';
export const SUBSCRIPTION_CHANGES_CONFIRMED_SUCCEEDED =
  'SUBSCRIPTION_CHANGES_CONFIRMED_SUCCEEDED';
export const SUBSCRIPTION_CHANGES_CONFIRMED_FAILED =
  'SUBSCRIPTION_CHANGES_CONFIRMED_FAILED';
export const SUBSCRIPTION_CHANGES_MAX_BOOSTABLE_PROPERTIES_SELECTED =
  'SUBSCRIPTION_CHANGES_MAX_BOOSTABLE_PROPERTIES_SELECTED';
export const SUBSCRIPTION_CHANGES_TYPE_OF_CLIENT_SELECTED =
  'SUBSCRIPTION_CHANGES_TYPE_OF_CLIENT_SELECTED';

registerEventHandler(
  CHANGE_SUBSCRIPTION_ACTION_REQUESTED,
  ({ environment: { apiUrl } }, { publisherId }) =>
    http.get({
      url: `${apiUrl}/backoffice/publishers/${publisherId}`,
      successEvent: CHANGE_SUBSCRIPTION_PUBLISHER_DATA_LOAD_SUCCEEDED,
      errorEvent: CHANGE_SUBSCRIPTION_PUBLISHER_DATA_LOAD_FAILED,
    }),
  [environment()]
);

registerEventHandler(
  CHANGE_SUBSCRIPTION_PUBLISHER_DATA_LOAD_SUCCEEDED,
  (_, [{ data }]) => ({
    ...state.set({
      changeSubscriptionModal: {
        selectedPublisher: data,
        selectedOptions: {
          ...convertPublisherToSelectedOptions(data),
          startDate: data.subscription.startDate,
          nextBillingDate: data.subscription.nextBillingDate,
          baseSubscriptionFinalPrice: data.subscription.totalPrice.amount,
          superboostFinalPrice: data.superboost.totalPrice.amount,
          reason: '',
        },
      },
    }),
    ...effects.dispatch({
      id: OPEN_DIALOG,
      payload: { id: CHANGE_SUBSCRIPTION_MODAL },
    }),
  })
);

registerEventHandler(CHANGE_SUBSCRIPTION_PUBLISHER_DATA_LOAD_FAILED, () =>
  toast.show({ text: 'Could not load publisher subscriptions' })
);

registerEventHandler(CHANGE_SUBSCRIPTION_VALUE_CHANGED, (_, { field, value }) =>
  state.merge({
    changeSubscriptionModal: {
      selectedOptions: { [field]: value !== '' ? value : null },
    },
  })
);

registerEventHandler(
  SUBSCRIPTION_CHANGES_CONFIRMED,
  ({ environment: { apiUrl }, state: { publisherId, body } }, payload) => ({
    ...http.patch({
      url: `${apiUrl}/backoffice/publishers/${publisherId}/subscriptions`,
      body,
      successEvent: { id: SUBSCRIPTION_CHANGES_CONFIRMED_SUCCEEDED, payload },
      errorEvent: SUBSCRIPTION_CHANGES_CONFIRMED_FAILED,
    }),
    ...state.set({
      changeSubscriptionModal: {
        inProgress: true,
      },
    }),
  }),
  [
    environment(),
    state.get({
      publisherId: 'changeSubscriptionModal.selectedPublisher.id',
      body: 'changeSubscriptionModal.selectedOptions',
    }),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGES_CONFIRMED_SUCCEEDED,
  (_, [__, { onSuccessEvent }]) => ({
    ...effects.dispatchMany([CLOSE_DIALOG, onSuccessEvent]),
    ...toast.show({ text: 'Current subscription correctly changed' }),
    ...state.set({
      changeSubscriptionModal: {
        inProgress: false,
      },
    }),
  })
);

registerEventHandler(
  SUBSCRIPTION_CHANGES_CONFIRMED_FAILED,
  (_, [{ data }]) => ({
    ...toast.show({ text: data ?? 'Error trying to change subscription' }),
    ...effects.dispatch(CLOSE_DIALOG),
  })
);

registerEventHandler(
  SUBSCRIPTION_CHANGES_MAX_BOOSTABLE_PROPERTIES_SELECTED,
  (
    {
      state: { plans, typeOfClient, countryCode },
      features: { PREMIUM_PLAN_4233 },
    },
    { maxBoostableProperties }
  ) => {
    if (!isMexico(countryCode)) {
      return state.merge({
        changeSubscriptionModal: {
          selectedOptions: {
            boostingLimit: maxBoostableProperties,
            planId: '',
          },
        },
      });
    }

    let filteredPlans = plans;
    if (PREMIUM_PLAN_4233) {
      filteredPlans = plans.filter((plan) => plan.price > 0);
      if (
        typeOfClient === MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM ||
        typeOfClient === MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM
      ) {
        const premiumPlan =
          findPremiumPlanByTypeOfClientAndMaxBoostableProperties(
            filteredPlans,
            typeOfClient,
            maxBoostableProperties
          );

        if (!premiumPlan) {
          return state.merge({
            changeSubscriptionModal: {
              selectedOptions: {
                boostingLimit: maxBoostableProperties,
                planId: null,
                typeOfClient: null,
              },
            },
          });
        }

        return state.merge({
          changeSubscriptionModal: {
            selectedOptions: {
              boostingLimit: maxBoostableProperties,
              planId: premiumPlan.id,
            },
          },
        });
      }
    }

    const foundPlan = findPlanByTypeOfClientAndMaxBoostableProperties(
      filteredPlans,
      typeOfClient,
      maxBoostableProperties
    );

    if (!foundPlan) {
      return state.merge({
        changeSubscriptionModal: {
          selectedOptions: {
            boostingLimit: maxBoostableProperties,
            planId: null,
            typeOfClient: null,
          },
        },
      });
    }
    return state.merge({
      changeSubscriptionModal: {
        selectedOptions: {
          boostingLimit: maxBoostableProperties,
          planId: foundPlan.id,
        },
      },
    });
  },
  [
    state.get({
      plans: PLANS_STATE_REGION,
      typeOfClient: `changeSubscriptionModal.selectedOptions.typeOfClient`,
      countryCode: `changeSubscriptionModal.selectedPublisher.countryCode`,
    }),
    featureFlags('PREMIUM_PLAN_4233'),
  ]
);

registerEventHandler(
  SUBSCRIPTION_CHANGES_TYPE_OF_CLIENT_SELECTED,
  (
    {
      state: { plans, maxBoostableProperties },
      features: { PREMIUM_PLAN_4233 },
    },
    { typeOfClient }
  ) => {
    if (!PREMIUM_PLAN_4233) {
      const plan = findPlanByTypeOfClientAndMaxBoostableProperties(
        plans,
        typeOfClient,
        maxBoostableProperties
      );
      return state.merge({
        changeSubscriptionModal: {
          selectedOptions: {
            planId: plan.id,
            typeOfClient,
          },
        },
      });
    }

    return state.merge({
      changeSubscriptionModal: {
        selectedOptions: {
          typeOfClient,
          boostingLimit: null,
          planId: '',
        },
      },
    });
  },
  [
    state.get({
      plans: PLANS_STATE_REGION,
      maxBoostableProperties: `changeSubscriptionModal.selectedOptions.boostingLimit`,
    }),
    featureFlags('PREMIUM_PLAN_4233'),
  ]
);
