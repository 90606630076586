import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import Price from '../../Price/Price';

const OfferPrice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 8px;

  ${Price} {
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
    text-align: center;
    font-size: 32px;
    font-style: normal;
    ${typography.weight(FONT_WEIGHT.SEMIBOLD)}
    line-height: normal;
    letter-spacing: -1px;
  }

  span:last-of-type {
    vertical-align: sub;
    padding: 8px 0;
    font-size: 16px;
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
    ${typography.weight(FONT_WEIGHT.MEDIUM)}
  }
`;

export default OfferPrice;
