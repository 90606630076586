import styled from 'styled-components';
import Table from 'design-system/components/Table';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import { FONT_WEIGHT } from 'design-system/styles/typography';
import Tooltip, { TOOLTIP_PLACEMENT } from 'design-system/components/Tooltip';
import Tag, { TAG_SIZE, TAG_VARIANT } from 'design-system/components/Tag';
import VerifiedEmailBadge from '../../../../partials/VerifiedEmailBadge';
import VerifiedPhoneBadge from '../../../../partials/VerifiedPhoneBadge';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';

const Wrapper = styled(Table.Cell)``;

const Badge = styled.div`
  ${Tag} {
    text-transform: capitalize;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XS)};
  width: 220px;

  > * {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const VerifiableDatum = styled.div`
  ${Body1} {
    ${spacing.inline(SPACING_SIZE.XS, false)}
  }
`;

const ContactTags = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XS)};
`;

export default function ContactColumn({
  eligibleLeadsForLeadPlus,
  eligibleLeadsForQualification,
  ...props
}) {
  const {
    value: { id, name, email, hasVerifiedEmail, phone, hasVerifiedPhone },
  } = useColumn(props);
  return (
    <Wrapper {...props}>
      <Content>
        <ContactInfo>
          <Body1 $weight={FONT_WEIGHT.MEDIUM}>{name}</Body1>
          <VerifiableDatum>
            <Body1 $weight={FONT_WEIGHT.LIGHT}>{email}</Body1>
            {hasVerifiedEmail && <VerifiedEmailBadge />}
          </VerifiableDatum>
          <VerifiableDatum>
            <Body1 $weight={FONT_WEIGHT.LIGHT}>{phone}</Body1>
            {hasVerifiedPhone && <VerifiedPhoneBadge />}
          </VerifiableDatum>
        </ContactInfo>
        <ContactTags>
          <Badge hidden={!eligibleLeadsForLeadPlus[id]}>
            <Tooltip
              title={
                <FormattedMessageWithValidation id="lead_plus_badge_tooltip" />
              }
              $placement={TOOLTIP_PLACEMENT.RIGHT}
            >
              <Tag variant={TAG_VARIANT.GOLD} size={TAG_SIZE.SMALL}>
                <FormattedMessageWithValidation id="lead_plus_badge" />
              </Tag>
            </Tooltip>
          </Badge>
          <Badge hidden={!eligibleLeadsForQualification[id]}>
            <Tooltip
              title={
                <FormattedMessageWithValidation id="qualified_badge_tooltip" />
              }
              $placement={TOOLTIP_PLACEMENT.RIGHT}
            >
              <Tag variant={TAG_VARIANT.BLUEBERRY_DARK} size={TAG_SIZE.SMALL}>
                <FormattedMessageWithValidation id="qualified" />
              </Tag>
            </Tooltip>
          </Badge>
          <Badge hidden={!hasVerifiedEmail && !hasVerifiedPhone}>
            <Tooltip
              title={
                <FormattedMessageWithValidation id="verified_badge_tooltip" />
              }
              $placement={TOOLTIP_PLACEMENT.RIGHT}
            >
              <Tag variant={TAG_VARIANT.PRIMARY_DARK} size={TAG_SIZE.SMALL}>
                <FormattedMessageWithValidation id="verified" />
              </Tag>
            </Tooltip>
          </Badge>
        </ContactTags>
      </Content>
    </Wrapper>
  );
}
