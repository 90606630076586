import Dialog from 'design-system/components/Dialog/Dialog';
import {
  FlatButton,
  PrimaryButton,
  SecondaryButton,
} from 'design-system/components/Button/presets';
import { useSelector } from 'reffects-store';
import { Body2 } from 'design-system/components/Typography/presets/Body2';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import { useMount } from 'react-use';
import { dispatch, dispatchMany } from 'reffects';
import { useEffect } from 'react';
import Price from '../../../Price/Price';
import { userIsPublisherCollaboratorSelector } from '../../../../selectors/user';
import {
  getPublisherMaxBoostablePropertiesFromSubscriptionSelector,
  getPublisherMaxBoostablePropertiesFromSuperboostSelector,
  isXSPublisherSelector,
  upsellSuperBoostPriceSelector,
} from '../../../../selectors/publisher';
import { supportEmailSelector } from '../../../../selectors/config';
import { useDialog } from '../../../../hooks/useDialog';
import {
  CREATE_UPSELL_SUPERBOOST_CTA_CLICKED,
  CREATE_UPSELL_SUPERBOOST_REQUEST,
  CREATE_UPSELL_SUPERBOOST_REQUEST_MODAL_VIEWED,
  SUPERBOOSTING_LIMIT_REACHED_MODAL,
  UPSELL_SUPERBOOST_REQUEST,
} from '../events';
import FormattedMessageWithValidation from '../../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import Offer from '../../../Offers/partials/Offer';
import OfferContent from '../../../Offers/partials/OfferContent';
import OfferHeading from '../../../Offers/partials/OfferHeading';
import OfferTitle from '../../../Offers/partials/OfferTitle';
import OfferSubtitle from '../../../Offers/partials/OfferSubtitle';
import OfferDetails from '../../../Offers/partials/OfferDetails';
import OfferPrice from '../../../Offers/partials/OfferPrice';

const Prices = styled.div`
  display: flex;
  flex-direction: column;
`;

const OriginalPrice = styled(Body2)`
  ${typography.weight(FONT_WEIGHT.NORMAL)};
  text-decoration: line-through;
`;

const OfferDiscount = styled(Body2)`
  ${color.text(COLOR_PALETTE.PRIMARY_A120)};
  ${typography.weight(FONT_WEIGHT.LIGHT)};
`;

const Action = styled.div`
  padding-top: ${spacing.value(SPACING_SIZE.L)};
`;
const CenteredOffer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function SuperboostingLimitReachedModal() {
  const { open, closeDialog } = useDialog(SUPERBOOSTING_LIMIT_REACHED_MODAL);
  const supportEmail = useSelector(supportEmailSelector);
  const isXS = useSelector(isXSPublisherSelector);
  const isPublisherCollaborator = useSelector(
    userIsPublisherCollaboratorSelector
  );
  const maxSuperboostableProperties = useSelector(
    getPublisherMaxBoostablePropertiesFromSuperboostSelector
  );
  const maxBoostableProperties = useSelector(
    getPublisherMaxBoostablePropertiesFromSubscriptionSelector
  );
  const superboostUpsell = useSelector(upsellSuperBoostPriceSelector);
  const isUpsellSuperBoostModal =
    isXS &&
    !isPublisherCollaborator &&
    maxBoostableProperties > maxSuperboostableProperties;

  useMount(() => {
    if (isUpsellSuperBoostModal) {
      dispatch(UPSELL_SUPERBOOST_REQUEST);
    }
  });

  useEffect(() => {
    if (open) {
      dispatch({
        id: CREATE_UPSELL_SUPERBOOST_REQUEST_MODAL_VIEWED,
        payload: {
          section: 'upsell-superboost-modal-properties-page',
        },
      });
    }
  }, [open]);

  let DialogBody = getDefaultDialogBody(supportEmail);

  if (isUpsellSuperBoostModal && superboostUpsell !== null) {
    DialogBody = upsellSuperBoostModal(
      open,
      closeDialog,
      superboostUpsell,
      maxSuperboostableProperties
    );
  }

  return (
    <Dialog open={open}>
      {DialogBody}
      <Dialog.Footer>
        <SecondaryButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="freelistings_button_continue" />
        </SecondaryButton>
      </Dialog.Footer>
    </Dialog>
  );
}

function getDefaultDialogBody(supportEmail) {
  return (
    <>
      <Dialog.Header>
        <FormattedMessageWithValidation id="superboosting_limit_reached_modal_title" />
      </Dialog.Header>
      <Dialog.Emphasis>
        <FormattedMessageWithValidation id="superboosting_limit_reached_modal_message" />
      </Dialog.Emphasis>
      <Dialog.Content>
        <Dialog.Text>
          <FormattedMessageWithValidation
            id="superboosting_limit_reached_modal_text"
            values={{
              supportEmail: (
                <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
              ),
            }}
          />
        </Dialog.Text>
      </Dialog.Content>
    </>
  );
}

function upsellSuperBoostModal(
  open,
  closeDialog,
  superboostUpsell,
  maxSuperboostableProperties
) {
  return (
    <Dialog open={open}>
      <Dialog.Header>
        <FormattedMessageWithValidation id="superboosting_upsell_modal_title" />
      </Dialog.Header>
      <Dialog.Content>
        <Dialog.Text>
          <strong>
            <FormattedMessageWithValidation
              id="superboosting_upsell_modal_text1"
              values={{
                maxSuperboostableProperties,
              }}
            />
          </strong>
        </Dialog.Text>
        <Dialog.Text>
          <FormattedMessageWithValidation id="superboosting_upsell_modal_text2" />
        </Dialog.Text>
      </Dialog.Content>
      <Dialog.Content>
        <CenteredOffer>
          <Offer>
            <OfferContent>
              <OfferHeading>
                <OfferTitle>
                  <FormattedMessageWithValidation id="subscription_upsell_superboost_title" />
                </OfferTitle>
                <OfferSubtitle>
                  <FormattedMessageWithValidation
                    id="subscription_upsell_superboost_text"
                    values={{
                      amount: 1,
                    }}
                  />
                </OfferSubtitle>
              </OfferHeading>
              <OfferDetails>
                <Prices>
                  {superboostUpsell?.originalPrice >
                    superboostUpsell?.discountedPrice && (
                    <OriginalPrice>
                      <Price
                        amount={superboostUpsell.originalPrice}
                        currency="CLF"
                      />
                    </OriginalPrice>
                  )}

                  <OfferPrice>
                    <Price
                      amount={superboostUpsell?.discountedPrice}
                      currency="CLF"
                    />
                    <span />
                  </OfferPrice>
                  {superboostUpsell?.discountPercentage > 0 && (
                    <OfferDiscount>
                      <FormattedMessageWithValidation
                        id="subscription_landing_plan_discount"
                        values={{
                          discount: superboostUpsell?.discountPercentage,
                        }}
                      />
                    </OfferDiscount>
                  )}
                </Prices>
              </OfferDetails>
            </OfferContent>
            <Action>
              <PrimaryButton
                onClick={() =>
                  dispatchMany([
                    {
                      id: CREATE_UPSELL_SUPERBOOST_REQUEST,
                      payload: {
                        section: 'upsell-superboost-modal-properties-page',
                      },
                    },
                    {
                      id: CREATE_UPSELL_SUPERBOOST_CTA_CLICKED,
                      payload: {
                        section: 'upsell-superboost-modal-properties-page',
                      },
                    },
                  ])
                }
              >
                <FormattedMessageWithValidation id="subscription_upsell_superboost_cta" />
              </PrimaryButton>
            </Action>
          </Offer>
        </CenteredOffer>
      </Dialog.Content>
      <Dialog.Footer>
        <FlatButton onClick={closeDialog}>
          <FormattedMessageWithValidation id="modal_button_close" />
        </FlatButton>
      </Dialog.Footer>
    </Dialog>
  );
}
