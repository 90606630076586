import { subscribe } from 'reffects-store';
import { dispatch } from 'reffects';
import { HEADCELLS } from './constants';
import { PLAN_CHANGED, PLAN_CHANGED_TABLE, RESET_PLAN_FIELD } from './events';
import { DataTable, TextColumn } from '../../../partials/DataTable';
import { PriceInputColumn } from './PriceInputColumn';

function SubscriptionPlanTable({
  editionField,
  currencyField,
  selectedCountry,
  onInputClick,
  onInputBlur,
  onInputChangeDataTable,
}) {
  if (selectedCountry == null) {
    return null;
  }
  return (
    <DataTable>
      <TextColumn
        id="listings"
        width={130}
        source="listingsName"
        title="Listings"
      />
      {HEADCELLS.map((header) => {
        if (header.id === 'listings') {
          return null;
        }
        const planNameVal = `b_${header.id}.name`;
        const amountValue = `b_${header.id}.${editionField}`;
        const currencyValue = `b_${header.id}.${currencyField}`;

        return (
          <PriceInputColumn
            key={header.id}
            id={`b_${header.id}`}
            title={header.label}
            source={{
              amount: amountValue,
              currency: currencyValue,
              planId: `b_${header.id}.id`,
              hasBeenModified: `b_${header.id}.hasBeenModified`,
              planName: planNameVal,
            }}
            defaultCurrency="CLP"
            onClick={(planId) => onInputClick(planId, editionField)}
            onBlur={(planId) => onInputBlur(planId, editionField)}
            onChange={(planName, e) =>
              onInputChangeDataTable(
                `${planName}.b_${header.id}`,
                editionField,
                e
              )
            }
          />
        );
      })}
    </DataTable>
  );
}

export default subscribe(SubscriptionPlanTable, () => {}, {
  onInputClick(planId, field) {
    dispatch({
      id: PLAN_CHANGED,
      payload: {
        planId,
        field,
        value: '',
      },
    });
  },
  onInputBlur(planId, field) {
    dispatch({
      id: RESET_PLAN_FIELD,
      payload: {
        planId,
        field,
      },
    });
  },
  onInputChangeDataTable(planId, field, e) {
    dispatch({
      id: PLAN_CHANGED_TABLE,
      payload: {
        planId,
        field,
        value: e.target.rawValue,
      },
    });
  },
});
