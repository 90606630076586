import { TYPE_OF_PLANS } from '../pages/CreatePublisher/constants';
import { normalizeTypeOfClient } from './normalizeTypeOfClient';

export default function findPlanByTypeOfClientAndMaxBoostableProperties(
  plans,
  typeOfClient,
  maxBoostableProperties
) {
  return plans.find(
    ({
      typeOfClient: planTypeOfClient,
      maxBoostableProperties: planMaxBoostableProperties,
      name: planName,
    }) =>
      planName.toLowerCase().includes(TYPE_OF_PLANS.PROFESSIONAL) &&
      planTypeOfClient === typeOfClient &&
      planMaxBoostableProperties === maxBoostableProperties
  );
}

export function findPremiumPlanByTypeOfClientAndMaxBoostableProperties(
  plans,
  typeOfClient,
  maxBoostableProperties
) {
  return plans.find(
    ({
      typeOfClient: planTypeOfClient,
      maxBoostableProperties: planMaxBoostableProperties,
      name: planName,
    }) =>
      planName.toLowerCase().includes(TYPE_OF_PLANS.PREMIUM) &&
      planTypeOfClient === normalizeTypeOfClient(typeOfClient) &&
      planMaxBoostableProperties === maxBoostableProperties
  );
}
