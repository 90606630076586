import { registerEffectHandler } from 'reffects';

export const NAVIGATE_TO = 'navigateTo';
export const OPEN_NEW_TAB = 'openNewTab';
export const REDIRECT_TO = 'redirectTo';
export const GO_BACK = 'goBack';
export const UPDATE_BROWSER_URL = 'updateBrowserUrl';

export default function registerRouteEffects(
  navigate,
  globalVariable = window
) {
  registerEffectHandler(NAVIGATE_TO, ({ path, keepSearchParams }) => {
    if (!keepSearchParams) {
      return navigate(path);
    }

    const currentUrl = new URL(globalVariable.location.href);
    return navigate(`${path}${currentUrl.search}`);
  });

  registerEffectHandler(OPEN_NEW_TAB, ({ url, target }) =>
    globalVariable.open(url, target)
  );

  registerEffectHandler(REDIRECT_TO, ({ url, keepSearchParams }) => {
    if (!keepSearchParams) {
      // eslint-disable-next-line no-param-reassign
      globalVariable.location.href = url;
      return;
    }

    const currentUrl = new URL(globalVariable.location.href);
    const newUrl = new URL(url);
    currentUrl.searchParams.forEach((value, name) => {
      newUrl.searchParams.set(name, value);
    });

    // eslint-disable-next-line no-param-reassign
    globalVariable.location.href = newUrl.toString();
  });

  registerEffectHandler(GO_BACK, () => globalVariable.history.back());

  registerEffectHandler(UPDATE_BROWSER_URL, (url) => {
    globalVariable.history.pushState({}, '', url);
  });
}

export function navigateTo(path, keepSearchParams = false) {
  return {
    [NAVIGATE_TO]: { path, keepSearchParams },
  };
}

export function openNewTab(url, target = null) {
  return {
    [OPEN_NEW_TAB]: { url, target },
  };
}

export function redirectTo(url, keepSearchParams = false) {
  return {
    [REDIRECT_TO]: { url, keepSearchParams },
  };
}

export function updateBrowserUrl(url) {
  return {
    [UPDATE_BROWSER_URL]: url,
  };
}

export function goBack() {
  return {
    [GO_BACK]: null,
  };
}
