import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { radius, RADIUS_SIZE } from 'design-system/styles/radius';
import { border, BORDER_SIZE } from 'design-system/styles/border';
import { color, COLOR_PALETTE } from 'design-system/styles/color';

const Offer = styled.div`
  padding: 16px 0 32px 0;
  display: flex;
  width: 236px;
  flex-direction: column;
  align-items: center;
  gap: ${spacing.value(SPACING_SIZE.S)};
  flex-shrink: 0;
  ${radius.regular(RADIUS_SIZE.LARGE)}
  ${border.outer({
    size: BORDER_SIZE.LARGE,
    color: COLOR_PALETTE.PRIMARY_BASE,
  })}
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)};
`;

export default Offer;
