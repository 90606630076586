import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import Icon, { ICON_SIZE } from 'design-system/components/Icon';
import {
  FONT_FAMILY,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

export const TAG_VARIANT = {
  DEFAULT: Symbol('TAG_VARIANT_DEFAULT'),
  PRIMARY: Symbol('TAG_VARIANT_PRIMARY'),
  PRIMARY_DARK: Symbol('TAG_VARIANT_PRIMARY_DARK'),
  SECONDARY: Symbol('TAG_VARIANT_SECONDARY'),
  COMPLEMENTARY_ORANGE: Symbol('TAG_VARIANT_COMPLEMENTARY_ORANGE'),
  EGGPLANT: Symbol('TAG_VARIANT_EGGPLANT'),
  BLUEBERRY: Symbol('TAG_VARIANT_BLUEBERRY'),
  BLUEBERRY_DARK: Symbol('TAG_VARIANT_BLUEBERRY_DARK'),
  COMPLEMENTARY_STRAWBERRY: Symbol('TAG_VARIANT_COMPLEMENTARY_STRAWBERRY'),
  COMPLEMENTARY_NEUTRAL: Symbol('COMPLEMENTARY_NEUTRAL'),
  CHAT: Symbol('TAG_VARIANT_CHAT'),
  CALL: Symbol('TAG_VARIANT_CALL'),
  GOLD: Symbol('TAG_VARIANT_GOLD'),
};

export const TAG_SIZE = {
  SMALL: Symbol('TAG_SIZE_SMALL'),
  BASE: Symbol('TAG_SIZE_BASE'),
};

export const TAG_ICON_POSITION = {
  START: Symbol('TAG_ICON_POSITION_START'),
  END: Symbol('TAG_ICON_POSITION_END'),
};

/**
 * The purpose of this container is to clip the icon and ensure the same height
 * in buttons with and without it.
 */
const IconContainer = styled.span`
  height: 0;

  ${Icon} {
    transform: translateY(-50%);
  }
`;

const Text = styled.span`
  white-space: nowrap;
  ${typography.font(FONT_FAMILY.PRIMARY)};
`;

const modDefaultVariant = () => css`
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}

  ${Text} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A60)}
  }
  ${Icon} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A40)}
  }
`;

const modPrimaryVariant = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_A10)}

  ${Text}, ${Icon} {
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
  }
`;
const modPrimaryDarkVariant = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_A120)}

  ${Text}, ${Icon} {
    ${color.text(COLOR_PALETTE.PRIMARY_A10)}
  }
`;

const modSecondaryVariant = () => css`
  ${color.background(COLOR_PALETTE.SECONDARY_A10)}

  ${Text}, ${Icon} {
    ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
  }
`;

const modComplementaryOrangeVariant = () => css`
  ${color.background(COLOR_PALETTE.PUMPKIN_A10)}

  ${Text}, ${Icon} {
    ${color.text(COLOR_PALETTE.PUMPKIN_BASE)}
  }
`;

const modEggplantVariant = () => css`
  ${color.background(COLOR_PALETTE.EGGPLANT_A10)}

  ${Text}, ${Icon} {
    ${color.text(COLOR_PALETTE.EGGPLANT_BASE)}
  }
`;

const modBlueberryVariant = () => css`
  ${color.background(COLOR_PALETTE.BLUEBERRY_A10)}

  ${Text}, ${Icon} {
    ${color.text(COLOR_PALETTE.BLUEBERRY_BASE)}
  }
`;

const modBlueberryDarkVariant = () => css`
  ${color.background(COLOR_PALETTE.BLUEBERRY_BASE)}

  ${Text}, ${Icon} {
    ${color.text(COLOR_PALETTE.BLUEBERRY_A10)}
  }
`;

const modComplementaryStrawberryVariant = () => css`
  ${color.background(COLOR_PALETTE.STRAWBERRY_A10)}

  ${Text}, ${Icon} {
    ${color.text(COLOR_PALETTE.STRAWBERRY_BASE)}
  }
`;

const modComplementaryNeutral = () => css`
  ${color.background(COLOR_PALETTE.NEUTRAL_A05)}

  ${Text}, ${Icon} {
    ${color.text(COLOR_PALETTE.NEUTRAL_A20)}
  }
`;

const modChatVariant = () => css`
  background: #f5f5f5;

  ${Text}, ${Icon} {
    color: #000000;
  }
`;

const modCallVariant = () => css`
  ${color.background(COLOR_PALETTE.PRIMARY_A10)}

  ${Text} {
    ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
  }
  ${Icon} {
    ${color.text(COLOR_PALETTE.PRIMARY_A120)}
  }
`;

const modGoldVariant = () => css`
  ${color.background(COLOR_PALETTE.GOLD_BASE)}
  ${Text} {
    ${color.text(COLOR_PALETTE.GOLD_A120)}
  }
  ${Icon} {
    ${color.text(COLOR_PALETTE.GOLD_A120)}
  }
`;

const modVariant = (variantName) =>
  ({
    [TAG_VARIANT.DEFAULT]: modDefaultVariant,
    [TAG_VARIANT.PRIMARY]: modPrimaryVariant,
    [TAG_VARIANT.PRIMARY_DARK]: modPrimaryDarkVariant,
    [TAG_VARIANT.SECONDARY]: modSecondaryVariant,
    [TAG_VARIANT.COMPLEMENTARY_ORANGE]: modComplementaryOrangeVariant,
    [TAG_VARIANT.EGGPLANT]: modEggplantVariant,
    [TAG_VARIANT.BLUEBERRY]: modBlueberryVariant,
    [TAG_VARIANT.BLUEBERRY_DARK]: modBlueberryDarkVariant,
    [TAG_VARIANT.COMPLEMENTARY_STRAWBERRY]: modComplementaryStrawberryVariant,
    [TAG_VARIANT.COMPLEMENTARY_NEUTRAL]: modComplementaryNeutral,
    [TAG_VARIANT.CHAT]: modChatVariant,
    [TAG_VARIANT.CALL]: modCallVariant,
    [TAG_VARIANT.GOLD]: modGoldVariant,
  }[variantName]);

const modIconPositionStart = () => css`
  flex-direction: row-reverse;
  padding-left: 6px;
  padding-right: 8px;

  ${IconContainer} {
    margin-right: ${spacing.value(SPACING_SIZE.S)};
    padding-left: initial;
  }
`;

const modIconPositionEnd = () => css`
  flex-direction: row;
  padding-right: 6px;
  padding-left: 8px;

  ${IconContainer} {
    margin-right: initial;
    margin-left: ${spacing.value(SPACING_SIZE.S)};
  }
`;

const modIconPosition = (position) =>
  ({
    [TAG_ICON_POSITION.START]: modIconPositionStart,
    [TAG_ICON_POSITION.END]: modIconPositionEnd,
  }[position]);

const modSizeSmall = () => css`
  ${spacing.insetSquish(SPACING_SIZE.XS)}

  ${IconContainer} {
    margin-right: ${spacing.value(SPACING_SIZE.XS)};
  }

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.XXSMALL)}
  }

  ${Text} {
    font-size: 10px;
    line-height: 12px;
    ${typography.weight(FONT_WEIGHT.MEDIUM)};
  }
`;

const modSizeBase = () => css`
  ${spacing.insetSquish(SPACING_SIZE.S)}

  ${Icon} {
    ${Icon.mods.size(ICON_SIZE.SMALL)}
  }

  ${Text} {
    font-size: 13px;
    line-height: 16px;
    ${typography.weight(FONT_WEIGHT.NORMAL)};
  }
`;

const modSize = (size) =>
  ({
    [TAG_SIZE.SMALL]: modSizeSmall,
    [TAG_SIZE.BASE]: modSizeBase,
  }[size]);

const Wrapper = styled.span`
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  text-decoration: none;

  ${() => modVariant(TAG_VARIANT.PRIMARY)}
  ${() => modIconPosition(TAG_ICON_POSITION.START)}
  ${({ variant }) => variant && modVariant(variant)}
  ${({ iconPosition }) => iconPosition && modIconPosition(iconPosition)}
  ${({ size = TAG_SIZE.BASE }) => modSize(size)}
`;

const Tag = forwardRef(function Tag({ children, icon, ...rest }, ref) {
  return (
    <Wrapper {...rest} ref={ref}>
      <Text>{children}</Text>
      {icon && <IconContainer>{icon}</IconContainer>}
    </Wrapper>
  );
});

Tag.mods = {
  variant: modVariant,
  iconPosition: modIconPosition,
  size: modSize,
};

export default styled(Tag)``;
