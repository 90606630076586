import { hasAutoRenewalSelector } from '../../../../selectors/publisher';
import { hasCancelRequestSelector } from '../../selectors';

export function showCancelAutoRenewalSelector(state) {
  return hasAutoRenewalSelector(state) && !hasCancelRequestSelector(state);
}

export function autoRenewalCancellationReasonSelector(state) {
  return state.requestAutoRenewalCancellation?.reason ?? '';
}

export function canSubmitCancellationRequestSelector(state) {
  const submitted = state.requestAutoRenewalCancellation?.submitted ?? false;
  return Boolean(autoRenewalCancellationReasonSelector(state)) && !submitted;
}
