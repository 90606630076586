import { effects, registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';
import { queryParamsGetAll } from '../../coeffects/queryParamsAll';
import { redirectTo } from '../../effects/routing';
import { analytics } from '../../effects/analytics';
import { loadCountryConfig } from '../../effects/loadCountryConfig';
import { loadTranslations } from '../../effects/loadTranslations';
import {
  COUNTRY_CONFIG_LOADED,
  TRANSLATIONS_LOADED_WITH_RENDER,
} from '../../App/events';

export const OFFERS_RETRIEVE_COUNTRY_CONFIG = 'OFFERS_RETRIEVE_COUNTRY_CONFIG';
export const OFFERS_RETRIEVE_COUNTRY_CONFIG_SUCCESS =
  'OFFERS_RETRIEVE_COUNTRY_CONFIG_SUCCESS';
export const OFFERS_MOUNTED = 'OFFERS_MOUNTED';
export const OFFERS_LOADED = 'OFFERS_LOADED';
export const OFFER_SELECTED = 'OFFER_SELECTED';
export const REDIRECT_TO_CHECKOUT = 'REDIRECT_TO_CHECKOUT';
export const CONTACT_SELECTED = 'CONTACT_SELECTED';
export const OFFERS_RETRIEVE_EMAIL_TRACKING = 'OFFERS_RETRIEVE_EMAIL_TRACKING';

registerEventHandler(
  OFFERS_RETRIEVE_COUNTRY_CONFIG,
  ({ environment: { apiUrl }, queryParamsAll: { countryCode } }) =>
    loadCountryConfig({
      apiUrl,
      country: countryCode,
      successEvent: OFFERS_RETRIEVE_COUNTRY_CONFIG_SUCCESS,
    }),
  [environment(), queryParamsGetAll()]
);

registerEventHandler(
  OFFERS_RETRIEVE_COUNTRY_CONFIG_SUCCESS,
  (_, [payload]) => ({
    ...effects.dispatch({ id: COUNTRY_CONFIG_LOADED, payload: [payload] }),
    ...loadTranslations({
      locale: payload.defaultLocale,
      onFinishEventId: TRANSLATIONS_LOADED_WITH_RENDER,
    }),
  })
);

registerEventHandler(
  OFFERS_MOUNTED,
  ({
    environment: { apiUrl },
    queryParamsAll: { publisherId },
    state: { localPublisherId },
  }) => {
    const id = localPublisherId ?? publisherId;
    return http.get({
      url: `${apiUrl}/self-service/plans?publisherId=${id}`,
      successEvent: OFFERS_LOADED,
    });
  },
  [
    environment(),
    queryParamsGetAll(),
    state.get({
      localPublisherId: 'publisher.id',
    }),
  ]
);

registerEventHandler(OFFERS_LOADED, (_, [{ data }]) =>
  state.set({ offers: data })
);

registerEventHandler(
  OFFER_SELECTED,
  (
    {
      environment: { apiUrl },
      queryParamsAll: { publisherId },
      state: { localPublisherId },
    },
    { offerId, trackingSection }
  ) => ({
    ...http.post({
      url: `${apiUrl}/self-service/scr/create`,
      body: {
        publisherId: localPublisherId ?? publisherId,
        selfServicePlanId: offerId,
        origin: trackingSection,
      },
      successEvent: REDIRECT_TO_CHECKOUT,
    }),
    ...analytics.trackClick('offer-selected', trackingSection, {
      publisherId: localPublisherId ?? publisherId,
      offerId,
      offerType: 'NewContract',
    }),
  }),
  [
    environment(),
    queryParamsGetAll(),
    state.get({
      localPublisherId: 'publisher.id',
    }),
  ]
);

registerEventHandler(REDIRECT_TO_CHECKOUT, (_, [{ data }]) =>
  redirectTo(data.link, true)
);

registerEventHandler(
  CONTACT_SELECTED,
  ({ queryParamsAll: { publisherId } }, { trackingSection }) =>
    analytics.trackClick('contact-selected', trackingSection, {
      publisherId,
    }),
  [queryParamsGetAll()]
);

registerEventHandler(
  OFFERS_RETRIEVE_EMAIL_TRACKING,
  ({ queryParamsAll: { publisherId, countryCode, origin, offerClick } }) => {
    if (origin && offerClick) {
      return {
        ...analytics.trackClick('offer-page-view', origin, {
          publisherId,
          countryCode,
          origin,
          offerClick,
        }),
      };
    }
    return {};
  },
  [queryParamsGetAll()]
);
