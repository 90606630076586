import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';

const OfferContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  text-align: center;
  gap: ${spacing.value(SPACING_SIZE.S)};
  padding: ${spacing.value(SPACING_SIZE.S)} ${spacing.value(SPACING_SIZE.M)} 0
    ${spacing.value(SPACING_SIZE.M)};
`;

export default OfferContent;
