import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useEffect } from 'react';
import Layout from 'design-system/components/Layout';
import ContactSupport from './partials/ContactSupport';
import PaymentInformation from './partials/PaymentInformation';
import FormattedMessageWithValidation from '../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import SubscriptionLayout from './partials/Layout/Layout';
import Header from './partials/Layout/Header';
import BillingSwitch from './partials/BillingSwitch/BillingSwitch';
import InvoicesTable from './partials/InvoicesTable/InvoicesTable';
import {
  shouldShowOffersSelector,
  subscriptionPageShowInvoicesTabSelector,
} from './selectors';
import {
  userIsPublisherCollaboratorSelector,
  userRoleSelector,
} from '../../selectors/user';
import SubscriptionDetailsModal from './partials/SubscriptionDetailsModal';
import { CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE } from './events';
import OffersCards from '../../partials/Offers/OffersCards/OffersCards';
import { showInvoicesSelector } from '../../selectors/config';
import SubscriptionTab from './partials/SubscriptionTab/SubscriptionTab';
import UpsellOffers from './partials/UpsellOffers/UpsellOffers';

export default function Subscription() {
  const showInvoicesTab = useSelector(subscriptionPageShowInvoicesTabSelector);
  const showInvoices = useSelector(showInvoicesSelector);
  const role = useSelector(userRoleSelector);
  const isPublisherCollaborator = useSelector(
    userIsPublisherCollaboratorSelector
  );
  const shouldShowOffers = useSelector(shouldShowOffersSelector);

  useEffect(
    () =>
      dispatch({
        id: CHECK_USER_IS_PUBLISHER_COLLABORATOR_ROLE,
        payload: { role },
      }),
    [role]
  );

  if (isPublisherCollaborator) {
    return null;
  }

  if (!showInvoicesTab && shouldShowOffers) {
    return (
      <SubscriptionLayout>
        <Layout.Padded>
          <Header>
            <FormattedMessageWithValidation id="subscription_section_header" />
          </Header>
          {showInvoices && <BillingSwitch />}
          <OffersCards pageName="my-subscription" />
        </Layout.Padded>
      </SubscriptionLayout>
    );
  }

  return (
    <SubscriptionLayout
      sidebar={
        <Layout.Padded>
          <PaymentInformation />
          <ContactSupport />
        </Layout.Padded>
      }
    >
      <Layout.Padded>
        <Header>
          <FormattedMessageWithValidation id="subscription_section_header" />
        </Header>
        {showInvoices && <BillingSwitch />}
        {showInvoicesTab ? <InvoicesTable /> : <SubscriptionTab />}
        <SubscriptionDetailsModal />
        <UpsellOffers />
      </Layout.Padded>
    </SubscriptionLayout>
  );
}
