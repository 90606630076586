import { get } from 'lodash';
import { PUBLISHER_TYPE } from '../../../constants/publisher';
import { BANK_TRANSFER } from '../../../constants/paymentMethod';
import {
  CREATE_PUBLISHER_PAGE,
  CREATION_STEP,
  INVALID_UUID_ERROR,
  MANDATORY_PUBLISHER_MAPPINGS_ERROR,
  MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE,
} from './constants';
import { planSelector } from '../selectors';
import filterMexicoOptionsByTypeOfClient from '../../utils/filterMexicoMaxBoostableOptionsByTypeOfClient';
import { COUNTRY_CODE } from '../../../constants/country';

export function maxBoostablePropertiesSelector(state) {
  const countryCode = selectedCountrySelector(state);
  const selectedTypeOfClient = typeOfClientSelector(state);
  const maxBoostableProperties = filterMexicoOptionsByTypeOfClient(
    countryCode,
    selectedTypeOfClient,
    planSelector(state)
  )
    .filter((plan) => plan.active)
    .filter((plan) => plan.price > 0)
    .map(({ maxBoostableProperties: maxProp }) => maxProp)
    .sort((maxProp, anotherMaxProp) => maxProp - anotherMaxProp);

  return Array.from(new Set(maxBoostableProperties));
}

export function planSelectorShouldBeDisabledSelector(state) {
  return (
    isMultipleAccountClientSharingSubscriptionSelector(state) ||
    (publisherPageSelector(state).plans?.length || 0) === 0
  );
}

export function isNextBillingDateDisabledSelector(state) {
  const { source } = publisherFormInfoSelector(state);
  if (source) {
    return (
      isFreePlanSelectedSelector(state) ||
      isMultipleAccountClientSharingSubscriptionSelector(state)
    );
  }
  return (
    isFreePlanSelectedSelector(state) ||
    isNewClientSelector(state) ||
    isMultipleAccountClientSharingSubscriptionSelector(state)
  );
}

export function isBillingCycleDisabledSelector(state) {
  return (
    isFreePlanSelectedSelector(state) ||
    isFreeTypeOfClientSelectedSelector(state) ||
    isMultipleAccountClientSharingSubscriptionSelector(state)
  );
}

export function isMaxBoostablePropertiesDisabledSelector(state) {
  return (
    isFreeTypeOfClientSelectedSelector(state) ||
    !selectedCountrySelector(state) ||
    isMultipleAccountClientSharingSubscriptionSelector(state)
  );
}

export function isSuperboostablePropertiesDisabledSelector(state) {
  return (
    isFreePlanSelectedSelector(state) ||
    isFreeTypeOfClientSelectedSelector(state)
  );
}

export function isNewClientSelector(state) {
  return publisherPageSelector(state).isNewPublisher ?? false;
}

export function publisherFormInfoSelector(state) {
  const {
    id,
    name,
    type,
    source,
    paymentMethod,
    billingDate,
    billingCycle,
    loadingAdsMode,
    billingContactEmail,
    typeOfClient,
    maxBoostablePropertiesSelected,
    superboostableProperties,
  } = publisherPageSelector(state);
  return {
    id,
    name,
    type,
    source,
    plan: selectedPlanIdSelector(state),
    billingDate: billingDate ?? '',
    paymentMethod: paymentMethod ?? '',
    country: selectedCountrySelector(state),
    isNewPublisher: isNewClientSelector(state),
    billingCycle: billingCycle ?? '',
    loadingAdsMode,
    billingContactEmail: billingContactEmail ?? '',
    multipleAccountClient: multipleAccountClientSelector(state),
    typeOfClient,
    maxBoostablePropertiesSelected,
    superboostableProperties,
  };
}

export function selectedMaxBoostablePropertiesSelector(state) {
  return publisherPageSelector(state).maxBoostablePropertiesSelected ?? '';
}

export function shouldShowPublishInThailandPropertySelector(state) {
  return selectedCountrySelector(state) === 'TH';
}

export function availablePaymentMethodsSelector(state) {
  if (isCreatingDeveloperSelector(state)) {
    return [{ id: BANK_TRANSFER, label: 'Bank Transfer' }];
  }
  return publisherPageSelector(state).availablePaymentMethods ?? [];
}

export function errorInvalidUuidSelector(state) {
  return get(state, INVALID_UUID_ERROR, false);
}

export function mandatoryPublisherMappingsErrorSelector(state) {
  return get(state, MANDATORY_PUBLISHER_MAPPINGS_ERROR, false);
}

export function isCreatingDeveloperSelector(state) {
  return selectedTypeSelector(state) === PUBLISHER_TYPE.DEVELOPER;
}

export function showLoadingAdsModeSelector(state) {
  return !isCreatingDeveloperSelector(state);
}

export function currentStepSelector(state) {
  return publisherPageSelector(state).step ?? CREATION_STEP.FORM;
}

export function publisherMappingsSelector(state) {
  return publisherPageSelector(state).publisherMappings ?? [];
}

export function selectedSuperboostablePropertiesSelector(state) {
  return publisherPageSelector(state).superboostableProperties;
}

export function shouldShowMultipleAccountOptionsSelector(state) {
  return (
    publisherFormInfoSelector(state).source == null &&
    isNewClientSelector(state)
  );
}

export function shouldShowBillingContactEmailInputSelector(state) {
  return (
    multipleAccountClientSelector(state) !==
    MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.SHARED
  );
}

export function isDisabledPaymentMethodSelectSelector(state) {
  return (
    availablePaymentMethodsSelector(state).length === 0 ||
    isMultipleAccountClientSharingSubscriptionSelector(state)
  );
}

export function isMultipleAccountClientSharingSubscriptionSelector(state) {
  return (
    multipleAccountClientSelector(state) ===
    MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.SHARED
  );
}

export function shouldShowSiblingPublisherAutocompleteSelector(state) {
  const multipleAccountClient = multipleAccountClientSelector(state);

  return (
    multipleAccountClient !== MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.NO &&
    multipleAccountClient !== MULTIPLE_ACCOUNT_CLIENT_SUBSCRIPTION_TYPE.MAIN
  );
}

export function publisherFormSubmitEnabled(state) {
  return (
    !publisherPageSelector(state).submittingForm &&
    !checkErrorOnMaxSuperboostableSelector(state)
  );
}

export function checkErrorOnMaxSuperboostableSelector(state) {
  const { maxBoostablePropertiesSelected, superboostableProperties } =
    publisherFormInfoSelector(state);

  return superboostableProperties > maxBoostablePropertiesSelected;
}

export function disabledAdditionalMonthSelector(state) {
  const billingCycleInMonths = selectedBillingCycleSelector(state);
  const countryCode = selectedCountrySelector(state);
  return (
    billingCycleInMonths === 1 ||
    (billingCycleInMonths === 3 && countryCode !== COUNTRY_CODE.CHILE)
  );
}

export function selectedAdditionalMonthSelector(state) {
  return publisherPageSelector(state).additionalMonth ?? '';
}

function isFreePlanSelectedSelector(state) {
  return publisherPageSelector(state).plan === 'free';
}

function typeOfClientSelector(state) {
  return publisherPageSelector(state)?.typeOfClient;
}

function isFreeTypeOfClientSelectedSelector(state) {
  return typeOfClientSelector(state) === 'free';
}

function selectedCountrySelector(state) {
  return publisherPageSelector(state).country;
}

function selectedTypeSelector(state) {
  return publisherPageSelector(state).type;
}

function multipleAccountClientSelector(state) {
  return publisherPageSelector(state).multipleAccountClient;
}

function selectedPlanIdSelector(state) {
  return publisherPageSelector(state).plan ?? '';
}

function selectedBillingCycleSelector(state) {
  return publisherPageSelector(state).billingCycle ?? '';
}

function publisherPageSelector(state) {
  return state[CREATE_PUBLISHER_PAGE] ?? {};
}
