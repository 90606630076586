import { registerEventHandler } from 'reffects';
import { http } from 'reffects-batteries';
import { state } from 'reffects-store';
import { environment } from '../../coeffects/environment';

export const CHECK_IF_PUBLISHER_HAS_UPSELL_OFFERS_AVAILABLE =
  'CHECK_IF_PUBLISHER_HAS_UPSELL_OFFERS_AVAILABLE';

export const PUBLISHER_HAS_UPSELL_OFFERS = 'PUBLISHER_HAS_UPSELL_OFFERS';
export const PUBLISHER_HAS_NO_UPSELL_OFFERS = 'PUBLISHER_HAS_NO_UPSELL_OFFERS';

registerEventHandler(
  CHECK_IF_PUBLISHER_HAS_UPSELL_OFFERS_AVAILABLE,
  ({ environment: { apiUrl } }) =>
    http.get({
      url: `${apiUrl}/self-service/plans/upsell/availability`,
      successEvent: PUBLISHER_HAS_UPSELL_OFFERS,
      errorEvent: PUBLISHER_HAS_NO_UPSELL_OFFERS,
    }),
  [environment()]
);

registerEventHandler(PUBLISHER_HAS_UPSELL_OFFERS, () =>
  state.set({ hasUpsellOffers: true })
);
registerEventHandler(PUBLISHER_HAS_NO_UPSELL_OFFERS, () =>
  state.set({ hasUpsellOffers: false })
);
