import { isMexico } from '../../utils/countries';
import { normalizeTypeOfClient } from './normalizeTypeOfClient';
import {
  MEXICO_TYPE_OF_CLIENT,
  TYPE_OF_PLANS,
} from '../pages/CreatePublisher/constants';

export default function filterMexicoOptionsByTypeOfClient(
  countryCode,
  selectedTypeOfClient,
  plans
) {
  if (!isMexico(countryCode)) {
    return plans;
  }

  if (!selectedTypeOfClient) {
    return [];
  }

  const normalizedSelectedTypeOfClient =
    normalizeTypeOfClient(selectedTypeOfClient);

  const plansFilteredByTypeOfClient = plans.filter(
    ({ typeOfClient }) => typeOfClient === normalizedSelectedTypeOfClient
  );

  if (
    selectedTypeOfClient === MEXICO_TYPE_OF_CLIENT.AGENCIA_PREMIUM ||
    selectedTypeOfClient === MEXICO_TYPE_OF_CLIENT.REMATES_PREMIUM
  ) {
    return plansFilteredByTypeOfClient.filter(({ name }) =>
      name.toLowerCase().includes(TYPE_OF_PLANS.PREMIUM)
    );
  }

  return plansFilteredByTypeOfClient.filter(({ name }) =>
    name.toLowerCase().includes(TYPE_OF_PLANS.PROFESSIONAL)
  );
}
