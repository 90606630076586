import { useTheme } from 'styled-components';
import OffersCardMobile from './OffersCardMobile';
import OffersCardDesktop from './OffersCardDesktop/OffersCardDesktop';

export default function OfferCard({ offerData, onClick }) {
  const theme = useTheme();

  return theme?.isMobile ? (
    <OffersCardMobile offerData={offerData} onClick={onClick} />
  ) : (
    <OffersCardDesktop offerData={offerData} onClick={onClick} />
  );
}
