import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch } from 'reffects';
import useTracking from '../../../../hooks/useTracking';
import OfferCard from '../../../Offers/OffersCard/OffersCard';
import { UPSELL_OFFERS_SELECTED } from '../../../Offers/OffersCard/events';

const Wrapper = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.XL)};
`;

export default function Offers({ offers, section, offerType }) {
  const handleOfferClick = (offerId) =>
    dispatch({
      id: UPSELL_OFFERS_SELECTED,
      payload: {
        offerId,
        section,
      },
    });
  const trackImpression = useTracking({
    track: {
      event: 'OffersViewed',
      section,
      payload: { offerType },
    },
  });
  return (
    <Wrapper ref={trackImpression}>
      {offers.map((offer) => (
        <OfferCard
          key={offer.id}
          offerData={offer}
          onClick={() => handleOfferClick(offer.id)}
        />
      ))}
    </Wrapper>
  );
}
