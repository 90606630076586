import { useIntersection } from 'react-use';
import { useEffect, useRef, useState } from 'react';
import { dispatch } from 'reffects';
import {
  TRACK,
  TRACK_CLICK,
  TRACK_ELEMENT_IS_VISIBLE,
} from '../partials/Tracking/events';

const useClickTracking = (ref, options) => {
  const { trackClick = false, sectionName } = options;
  useEffect(() => {
    const clickListener = () => {
      dispatch({
        id: TRACK_CLICK,
        payload: {
          eventName: trackClick.eventName,
          sectionName,
          payload: trackClick.payload,
        },
      });
    };

    if (trackClick) {
      ref.current?.addEventListener('click', clickListener);
    }

    return () => {
      if (trackClick) {
        ref.current?.removeEventListener('click', clickListener);
      }
    };
  }, [ref]);
};

export const useImpressionTracking = (
  ref,
  { sectionName, trackImpression = false }
) => {
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });
  const [seen, setSeen] = useState(false);

  useEffect(() => {
    if (
      trackImpression &&
      intersection &&
      intersection?.isIntersecting &&
      !seen
    ) {
      setSeen(true);
      dispatch({
        id: TRACK_ELEMENT_IS_VISIBLE,
        payload: {
          name: trackImpression.elementName,
          sectionName,
          visible: intersection.isIntersecting,
          ...(trackImpression.payload ?? {}),
        },
      });
    }
  }, [intersection]);
};

export const useTrack = (ref, { track = null }) => {
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });
  const [seen, setSeen] = useState(false);

  useEffect(() => {
    if (track && intersection && intersection?.isIntersecting && !seen) {
      setSeen(true);
      dispatch({
        id: TRACK,
        payload: {
          event: track.event,
          section: track.section,
          payload: track.payload,
        },
      });
    }
  }, [intersection]);
};

export default (options) => {
  let refToUse = useRef(null);
  if (options.ref) {
    refToUse = harmonizeRef(options.ref);
  }

  useClickTracking(refToUse, options);
  useImpressionTracking(refToUse, options);
  useTrack(refToUse, options);

  return refToUse;
};

function harmonizeRef(ref) {
  if (ref === null || ref.current === null || ref.current) {
    return ref;
  }

  return { current: ref };
}
