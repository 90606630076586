import { useSelector } from 'reffects-store';
import { useMount, useUnmount } from 'react-use';
import { dispatch } from 'reffects';
import { hasUpsellOffersSelector } from './selectors';
import UpsellOffersContent from './partials/UpsellOffersContent/UpsellOffersContent';
import { UPSELL_OFFERS_MOUNTED, UPSELL_OFFERS_UNMOUNTED } from './events';
import { upsellOffersSelector } from '../../../../partials/Offers/selectors';

export default function UpsellOffers() {
  const offers = useSelector(upsellOffersSelector);
  const hasUpsellOffers = useSelector(hasUpsellOffersSelector);

  useMount(() => {
    dispatch(UPSELL_OFFERS_MOUNTED);
  });

  useUnmount(() => {
    dispatch(UPSELL_OFFERS_UNMOUNTED);
  });

  if (!hasUpsellOffers) {
    return null;
  }

  return <UpsellOffersContent offers={offers} />;
}
