import styled from 'styled-components';
import Table from 'design-system/components/Table';
import Tag, { TAG_SIZE, TAG_VARIANT } from 'design-system/components/Tag';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import {
  LEAD_STATUS,
  leadStatusTranslationTags,
} from '../../../../constants/lead';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import { useFeatureSelector } from '../../../../utils/featuresFlags/selectors';

const New = styled(Tag).attrs({
  children: (
    <FormattedMessageWithValidation
      id={leadStatusTranslationTags[LEAD_STATUS.NEW]}
    />
  ),
  size: TAG_SIZE.SMALL,
})`
  ${Tag.mods.variant(TAG_VARIANT.PRIMARY)}
  ${({ NEW_INBOX_4238 }) => NEW_INBOX_4238 && 'background-color: transparent;'}
`;

const Lost = styled(Tag).attrs({
  children: (
    <FormattedMessageWithValidation
      id={leadStatusTranslationTags[LEAD_STATUS.LOST]}
    />
  ),
  size: TAG_SIZE.SMALL,
})`
  ${Tag.mods.variant(TAG_VARIANT.COMPLEMENTARY_NEUTRAL)}
  ${({ NEW_INBOX_4238 }) => NEW_INBOX_4238 && 'background-color: transparent;'}
`;

const Default = styled(Tag).attrs(({ status }) => ({
  children: (
    <FormattedMessageWithValidation id={leadStatusTranslationTags[status]} />
  ),
  size: TAG_SIZE.SMALL,
}))`
  ${Tag.mods.variant(TAG_VARIANT.SECONDARY)}
  ${({ NEW_INBOX_4238 }) => NEW_INBOX_4238 && 'background-color: transparent;'}
`;

export default function StatusColumn(props) {
  const { value } = useColumn(props);

  return (
    <Table.Cell {...props}>
      <StatusTag status={value} />
    </Table.Cell>
  );
}

function StatusTag({ status }) {
  const NEW_INBOX_4238 = useFeatureSelector('NEW_INBOX_4238');
  if (status === LEAD_STATUS.NEW) {
    return <New NEW_INBOX_4238={NEW_INBOX_4238} />;
  }
  if (status === LEAD_STATUS.LOST) {
    return <Lost NEW_INBOX_4238={NEW_INBOX_4238} />;
  }
  return <Default status={status} NEW_INBOX_4238={NEW_INBOX_4238} />;
}
