import Table from 'design-system/components/Table';
import Tag, { TAG_SIZE, TAG_VARIANT } from 'design-system/components/Tag';
import Icon from 'design-system/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENT } from 'design-system/components/Tooltip';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';

function SuperHotTag() {
  return (
    <Tooltip
      title={
        <FormattedMessageWithValidation id="enqpage_table_hotness_super_hot_tooltip" />
      }
      $placement={TOOLTIP_PLACEMENT.RIGHT}
    >
      <Tag
        icon={<Icon glyph="superHot" />}
        variant={TAG_VARIANT.COMPLEMENTARY_STRAWBERRY}
        size={TAG_SIZE.SMALL}
      >
        <FormattedMessageWithValidation id="enqpage_table_hotness_super_hot" />
      </Tag>
    </Tooltip>
  );
}

function HotTag() {
  return (
    <Tooltip
      title={
        <FormattedMessageWithValidation id="enqpage_table_hotness_hot_tooltip" />
      }
      $placement={TOOLTIP_PLACEMENT.RIGHT}
    >
      <Tag
        icon={<Icon glyph="hot" />}
        variant={TAG_VARIANT.COMPLEMENTARY_ORANGE}
        size={TAG_SIZE.SMALL}
      >
        <FormattedMessageWithValidation id="enqpage_table_hotness_hot" />
      </Tag>
    </Tooltip>
  );
}

export default function HotnessColumn({ eligibleLeadsForHotness, ...props }) {
  const {
    value: { id },
  } = useColumn(props);
  const value = eligibleLeadsForHotness[id];
  const hotnessByTag = {
    superHot: <SuperHotTag />,
    hot: <HotTag />,
  };
  return <Table.Cell>{hotnessByTag[value] ?? <div />}</Table.Cell>;
}
