import { registerEventHandler } from 'reffects';
import { queryParamsGetAll } from '../../../coeffects/queryParamsAll';
import { analytics } from '../../../effects/analytics';

export const CHECK_SELF_SERVICE_MOUNTED = 'CHECK_SELF_SERVICE_MOUNTED';

registerEventHandler(
  CHECK_SELF_SERVICE_MOUNTED,
  ({ queryParamsAll: { selfServiceNotificationId } }) =>
    analytics.trackImpression(
      `self-service-publisher-checkout-id-${selfServiceNotificationId}`,
      'self-service-checkout',
      { visible: true }
    ),
  [queryParamsGetAll()]
);
