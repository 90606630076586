import { Subtitle1 } from 'design-system/components/Typography/presets/Subtitle1';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { dispatch } from 'reffects';
import FormattedMessageWithValidation from '../../../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import UnreadCount from '../../../../../../partials/UnreadCount/UnreadCount';
import OfferCard from '../../../../../../partials/Offers/OffersCard/OffersCard';
import useTracking from '../../../../../../hooks/useTracking';
import { UPSELL_TRACKING_SECTION } from '../../constants';
import { UPSELL_OFFERS_SELECTED } from '../../../../../../partials/Offers/OffersCard/events';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.L)};
`;

const Heading = styled.header`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.S)};
`;

const Cards = styled.div`
  display: flex;
  gap: ${spacing.value(SPACING_SIZE.L)};
`;

export default function UpsellOffersContent({ offers }) {
  const trackImpression = useTracking({
    track: {
      event: 'OffersViewed',
      section: UPSELL_TRACKING_SECTION,
      payload: { offerType: 'Upsell' },
    },
  });

  const handleOfferClick = (offerId) =>
    dispatch({
      id: UPSELL_OFFERS_SELECTED,
      payload: {
        offerId,
        section: UPSELL_TRACKING_SECTION,
      },
    });

  return (
    <Section>
      <Heading>
        <Subtitle1>
          <FormattedMessageWithValidation id="subscription_upsell_offers_title" />
        </Subtitle1>
        <UnreadCount />
      </Heading>
      <Cards ref={trackImpression}>
        {offers?.map((offer) => (
          <OfferCard
            key={offer.id}
            offerData={offer}
            onClick={() => handleOfferClick(offer.id)}
          />
        ))}
      </Cards>
    </Section>
  );
}
