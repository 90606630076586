import styled, { css } from 'styled-components';
import { useSelector } from 'reffects-store';
import { useMount } from 'react-use';
import { dispatch } from 'reffects';
import { Heading1 } from 'design-system/components/Typography/presets/Heading1';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { FONT_WEIGHT, typography } from 'design-system/styles/typography';
import FormattedMessageWithValidation from '../../FormattedMessageWithValidation/FormattedMessageWithValidation';
import OfferCard from '../OffersCard/OffersCard';
import {
  CONTACT_SELECTED,
  OFFER_SELECTED,
  OFFERS_MOUNTED,
} from '../../../pages/Offers/events';
import useTracking from '../../../hooks/useTracking';
import { supportEmailSelector } from '../../../selectors/config';
import { newContractOffersSelector } from '../selectors';

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${spacing.value(SPACING_SIZE.XL)};
  background-color: #0acc86;
  padding: ${spacing.value(SPACING_SIZE.XL)} 0;

  p {
    margin: 0;
  }

  ${(props) =>
    props.theme.isMobile &&
    css`
      justify-content: flex-start;
      padding: ${spacing.value(SPACING_SIZE.M)};
      gap: ${spacing.value(SPACING_SIZE.M)};
    `}
`;

const Title = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${spacing.value(SPACING_SIZE.XL)} 0;
    gap: ${spacing.value(SPACING_SIZE.S)};

    ${(props) =>
      props.theme.isMobile &&
      css`
        padding: 0;
      `}
    ${Heading1} {
        color: #fff;
        font-size: 36px;

        ${(props) =>
          props.theme.isMobile &&
          css`
            font-size: 24px;
            ${typography.weight(FONT_WEIGHT.NORMAL)};
            line-height: normal;
            text-align: center;
          `}
`;

const Disclaimer = styled.div`
  span {
    color: #fff;
  }

  ${(props) =>
    props.theme.isMobile &&
    css`
      text-align: center;
    `}
`;

const DisclaimerMobile = styled.div`
  span {
    color: #fff;
  }

  text-align: center;
  ${(props) =>
    !props.theme.isMobile &&
    css`
      display: none;
    `}
`;

const Cards = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;
  max-width: 1192px;

  ${(props) =>
    props.theme.isMobile &&
    css`
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: ${spacing.value(SPACING_SIZE.M)};
      width: 100%;
    `}
`;

const ContactUs = styled.div`
  padding: 40px 10px;
  display: flex;
  width: 280px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 48px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #0acc86;
  background: #fff;
  ${(props) =>
    props.theme.isMobile &&
    css`
      display: none;
    `}
`;

const ContactUsMessage = styled.div`
  color: #414963;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
`;

function OffersCards({ pageName, ...props }) {
  const offers = useSelector(newContractOffersSelector);
  const trackingSection = pageName
    ? `${pageName}-subscription-offers`
    : 'subscription-offers';
  const trackImpression = useTracking({
    trackImpression: {
      elementName: 'offers',
      payload: { offerType: 'NewContract' },
    },
    sectionName: trackingSection,
  });
  const supportEmail = useSelector(supportEmailSelector);

  useMount(() => {
    dispatch(OFFERS_MOUNTED);
  });

  const handleOfferClick = (offerId) => {
    dispatch({
      id: OFFER_SELECTED,
      payload: { offerId, trackingSection },
    });
  };

  const handleContactClick = () => {
    dispatch({
      id: CONTACT_SELECTED,
      payload: { trackingSection },
    });
  };

  return (
    <Content {...props}>
      <Title ref={trackImpression}>
        <Heading1>
          <FormattedMessageWithValidation id="subscription_landing_title1" />
        </Heading1>
        <Heading1>
          <FormattedMessageWithValidation id="subscription_landing_title2" />
        </Heading1>
      </Title>
      <Cards>
        {offers?.map((offer) => (
          <OfferCard
            key={offer.id}
            offerData={offer}
            onClick={() => handleOfferClick(offer.id)}
          />
        ))}
        <ContactUs>
          <ContactUsMessage>
            <FormattedMessageWithValidation
              id="subscription_landing_contact_us_message"
              values={{
                supportEmail: (
                  <a
                    href={`mailto:${supportEmail}`}
                    onClick={handleContactClick}
                  >
                    {supportEmail}
                  </a>
                ),
              }}
            />
          </ContactUsMessage>
        </ContactUs>
      </Cards>
      <Disclaimer>
        <FormattedMessageWithValidation id="subscription_landing_disclaimer" />
      </Disclaimer>
      <DisclaimerMobile>
        <FormattedMessageWithValidation id="subscription_landing_plan_vat_notice" />
      </DisclaimerMobile>
      <DisclaimerMobile>
        <FormattedMessageWithValidation
          id="subscription_landing_contact_us_message"
          values={{
            supportEmail: (
              <a href={`mailto:${supportEmail}`} onClick={handleContactClick}>
                {supportEmail}
              </a>
            ),
          }}
        />
      </DisclaimerMobile>
    </Content>
  );
}

export default styled(OffersCards)``;
