import styled from 'styled-components';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import { Subtitle2 } from 'design-system/components/Typography/presets/Subtitle2';

const OfferSubtitle = styled(Subtitle2)`
  display: flex;
  flex-direction: column;

  span {
    ${color.text(COLOR_PALETTE.SECONDARY_BASE)}
    font-style: normal;
    line-height: normal;
  }
`;

export default OfferSubtitle;
