import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { useEffect, useRef } from 'react';
import ScrollableArea from 'design-system/components/ScrollableArea/ScrollableArea';

const Wrapper = styled(ScrollableArea)`
  flex: 1;
  max-height: initial;
  margin-left: ${spacing.value(SPACING_SIZE.XL)};
  margin-right: ${spacing.value(SPACING_SIZE.XL)};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    ${spacing.stack(SPACING_SIZE.M)}

    margin-right: ${spacing.value(SPACING_SIZE.S)};
    max-width: 70%;
  }
`;

export default function Timeline({ children }) {
  const lastRef = useRef(null);

  useEffect(() => {
    lastRef?.current.scrollIntoView();
  }, [children]);

  return (
    <Wrapper>
      <Content>{children}</Content>
      <div ref={lastRef} />
    </Wrapper>
  );
}
