import { registerEventHandler } from 'reffects';
import { analytics } from '../../effects/analytics';
import { tracking } from '../../effects/tracking';

export const TRACK_CLICK = 'TRACK_CLICK';
export const TRACK_ELEMENT_IS_VISIBLE = 'TRACK_ELEMENT_IS_VISIBLE';
export const TRACK_PAGE_VIEW_MOUNTED = 'TRACK_PAGE_VIEW_MOUNTED';
export const TRACK = 'TRACK';

export default function registerLinkTrackingEvents() {
  registerEventHandler(TRACK_CLICK, (_, { eventName, sectionName, payload }) =>
    analytics.trackClick(eventName, sectionName, payload)
  );

  registerEventHandler(
    TRACK_PAGE_VIEW_MOUNTED,
    (_, { pageViewName, payload }) =>
      pageViewName ? analytics.trackPageView(pageViewName, payload) : {}
  );

  registerEventHandler(
    TRACK_ELEMENT_IS_VISIBLE,
    (_, { name, sectionName, ...payload }) =>
      analytics.trackImpression(name, sectionName, payload)
  );
}

export function registerTrackingEvents() {
  registerEventHandler(TRACK, (_, { event, section, payload }) =>
    tracking.track(event, section, payload)
  );
}
