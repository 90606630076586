import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { useMount } from 'react-use';
import { hasSelfServicePublisherSelector } from '../selectors';
import { useScript } from '../../../hooks/useScript';
import { CHECK_SELF_SERVICE_MOUNTED } from './events';

function WithHotJar({ children, id, version }) {
  useScript(`https://static.hotjar.com/c/hotjar-${id}.js?sv=${version}`, {
    removeOnUnmount: true,
  });
  return children;
}

export function CheckSelfService({ children }) {
  const isSelfService = useSelector(hasSelfServicePublisherSelector);

  useMount(() => {
    dispatch(CHECK_SELF_SERVICE_MOUNTED);
  });

  // eslint-disable-next-line no-underscore-dangle
  const { hjid, hjsv } = window._hjSettings;
  return isSelfService && hjid ? (
    <WithHotJar id={hjid} version={hjsv}>
      {children}
    </WithHotJar>
  ) : (
    children
  );
}
