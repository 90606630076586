import { isUserLoggedInSelector } from '../../../App/selectors';
import {
  homeRoute,
  loginRoute,
  subscriptionRoute,
} from '../../../utils/proppitWebRouter';
import { userEmailSelector, userRoleSelector } from '../../../selectors/user';
import {
  publisherCountrySelector,
  publisherSelector,
  publisherSubscriptionIdSelector,
} from '../../../selectors/publisher';
import { SUBSCRIPTION_CHANGE_TYPE } from '../../../Backoffice/constants/subscription';

export function continueLinkSelector(state) {
  if (!isUserLoggedInSelector(state)) {
    return loginRoute();
  }
  if (
    publisherSelector(state).subscriptionChangeRequestType ===
    SUBSCRIPTION_CHANGE_TYPE.NEW_CONTRACT
  ) {
    return homeRoute();
  }
  return subscriptionRoute();
}

export function segmentUserDataSelector(state) {
  return {
    email: userEmailSelector(state),
    role: userRoleSelector(state) ?? 'not-logged',
    subscriptionId: publisherSubscriptionIdSelector(state),
    countryCode: publisherCountrySelector(state),
  };
}
