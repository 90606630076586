import { registerEventHandler } from 'reffects';
import { redirectTo } from '../../../effects/routing';
import { tracking } from '../../../effects/tracking';
import { environment } from '../../../coeffects/environment';

export const UPSELL_OFFERS_SELECTED = 'UPSELL_OFFERS_SELECTED';

registerEventHandler(
  UPSELL_OFFERS_SELECTED,
  ({ environment: { apiUrl } }, { offerId, section }) => ({
    ...redirectTo(
      `${apiUrl}/self-service/scr/upsell/${offerId}?origin=${section}`
    ),
    ...tracking.track('OfferSelected', section, {
      offerId,
      offerType: 'Upsell',
    }),
  }),
  [environment()]
);
