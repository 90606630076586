import { useSelector } from 'reffects-store';
import { hasPermissionForActionSelector } from '../selectors/permissions';

export function ProtectedComponent({ action, forced, children }) {
  const show = useSelector(hasPermissionForActionSelector(action));

  if (!forced && !show) {
    return null;
  }

  return children;
}
