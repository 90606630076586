import { publishedPropertiesSelector } from '../../../selectors/propertiesSummary';
import {
  isAgencySelector,
  publisherCountrySelector,
  publisherSubscriptionPlanIsFreeSelector,
} from '../../../selectors/publisher';
import { COUNTRY_CODE } from '../../../constants/country';
import { publisherHasUpsellOffersSelector } from '../../../selectors/upsellOffers/selectors';

export function showSubscriptionBadgeSelector(state) {
  const availableCountries = [
    COUNTRY_CODE.ARGENTINA,
    COUNTRY_CODE.PERU,
    COUNTRY_CODE.CHILE,
    COUNTRY_CODE.COLOMBIA,
    COUNTRY_CODE.MEXICO,
    COUNTRY_CODE.INDONESIA,
  ];
  const isAgency = isAgencySelector(state);
  const publishedProperties = publishedPropertiesSelector(state);
  const isFree = publisherSubscriptionPlanIsFreeSelector(state);
  const countryCode = publisherCountrySelector(state);
  const hasUpsellOffers = publisherHasUpsellOffersSelector(state);
  if (countryCode === COUNTRY_CODE.ARGENTINA) {
    return isAgency && isFree;
  }
  return (
    (isAgency &&
      availableCountries.includes(countryCode) &&
      publishedProperties <= 10 &&
      isFree) ||
    hasUpsellOffers
  );
}
