import { effects, registerEventHandler } from 'reffects';
import { http, queryParams } from 'reffects-batteries';
import { state } from 'reffects-store';
import userData from '../../userData';
import { COUNTRY_CONFIG_LOADED } from '../../App/events';
import { navigateTo } from '../../effects/routing';
import { environment } from '../../coeffects/environment';
import { loadTranslations } from '../../effects/loadTranslations';
import { analytics } from '../../effects/analytics';
import { loadCountryConfig } from '../../effects/loadCountryConfig';
import {
  INVALID_PASSWORD,
  MISMATCHED_PASSWORDS,
  SERVER_ERROR,
} from '../ChangePassword/constants';
import {
  PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED,
  PUBLISHER_PAYMENT_METHODS_REQUESTED,
  PUBLISHER_REQUESTED,
  START_LOGGED_PUBLISHER_CHECKING,
} from '../../events';
import { OPEN_DIALOG } from '../../events/dialogs';
import { TERMS_AND_CONDITIONS_DIALOG } from '../../partials/TermsAndConditionsDialog/constants';
import { IS_LOGGED_IN } from './constants';
import { CHURN_MODAL } from '../../partials/modals/churn/constants';
import { VERIFIED_LEADS_STATS_REQUESTED } from '../../partials/LeadQualityAwareness/events';
import { CHECK_IF_PUBLISHER_HAS_UPSELL_OFFERS_AVAILABLE } from '../../events/upsellOffers/events';

const PAGE = 'login';
const LOGIN_HAS_FAILED = `${PAGE}:loginFailed`;

export const AUTHENTICATION_PAGE_MOUNTED = 'AUTHENTICATION_PAGE_MOUNTED';

export const LOG_IN_SUBMITTED = 'LOG_IN_SUBMITTED';
export const LOGGED_IN = 'LOGGED_IN';
export const LOG_IN_FAILED = 'LOG_IN_FAILED';
export const USER_DATA_LOADED_SUCCESS_AFTER_LOGIN =
  'USER_DATA_LOADED_SUCCESS_AFTER_LOGIN';
export const USER_DATA_LOAD_FAILED_AFTER_LOGIN =
  'USER_DATA_LOAD_FAILED_AFTER_LOGIN';
export const TRANSLATIONS_LOADED_WITHOUT_RENDER =
  'TRANSLATIONS_LOADED_WITHOUT_RENDER';

export const FORGOT_PASSWORD_PAGE_MOUNTED = 'FORGOT_PASSWORD_PAGE_MOUNTED';
export const PASSWORD_RECOVERY_REQUESTED = 'PASSWORD_RECOVERY_REQUESTED';
export const PASSWORD_RECOVERY_REQUEST_SUCCESSFUL =
  'PASSWORD_RECOVERY_REQUEST_SUCCESSFUL';
export const PASSWORD_RECOVERY_REQUEST_FAILED =
  'PASSWORD_RECOVERY_REQUEST_FAILED';

export const RESET_PASSWORD_REQUESTED = 'RESET_PASSWORD_REQUESTED';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_FAILED = 'PASSWORD_RESET_FAILED';
export const GO_TO_LOGIN = 'GO_TO_LOGIN';

registerEventHandler(
  AUTHENTICATION_PAGE_MOUNTED,
  ({ queryParams: { 'user-from-lamudi': userFromLamudi } }) =>
    state.set({ userFromLamudi: Boolean(userFromLamudi) }),
  [queryParams.get(['user-from-lamudi'])]
);

registerEventHandler(
  LOG_IN_SUBMITTED,
  ({ environment: { apiUrl } }, { email, password }) =>
    http.post({
      url: `${apiUrl}/login`,
      body: JSON.stringify({ email, password }),
      successEvent: [LOGGED_IN],
      errorEvent: [LOG_IN_FAILED],
    }),
  [environment()]
);

registerEventHandler(
  LOGGED_IN,
  ({ environment: { apiUrl } }) => ({
    ...state.set({
      [IS_LOGGED_IN]: true,
      [LOGIN_HAS_FAILED]: false,
    }),
    ...userData.request({
      apiUrl,
      successEvent: USER_DATA_LOADED_SUCCESS_AFTER_LOGIN,
      errorEvent: USER_DATA_LOAD_FAILED_AFTER_LOGIN,
    }),
    ...effects.dispatchMany([
      PUBLISHER_REQUESTED,
      PUBLISHER_PAYMENT_METHODS_REQUESTED,
      PENDING_SUBSCRIPTION_CHANGE_REQUEST_REQUESTED,
      VERIFIED_LEADS_STATS_REQUESTED,
    ]),
  }),
  [environment()]
);

registerEventHandler(USER_DATA_LOAD_FAILED_AFTER_LOGIN, () => {});

registerEventHandler(
  USER_DATA_LOADED_SUCCESS_AFTER_LOGIN,
  ({ state: { redirectAfterLogin }, environment: { apiUrl } }, [{ data }]) => {
    const {
      country,
      locale,
      isBackofficeUser,
      acceptedTermsAndConditions,
      askChurnReason,
      isSelfServicePublisher,
    } = data;
    const events = [
      { id: START_LOGGED_PUBLISHER_CHECKING, payload: { isBackofficeUser } },
      { id: CHECK_IF_PUBLISHER_HAS_UPSELL_OFFERS_AVAILABLE },
    ];

    if (!acceptedTermsAndConditions) {
      events.push({
        id: OPEN_DIALOG,
        payload: { id: TERMS_AND_CONDITIONS_DIALOG },
      });
    }

    if (askChurnReason) {
      events.push({
        id: OPEN_DIALOG,
        payload: { id: CHURN_MODAL },
      });
    }
    let effectDescriptions = {
      ...userData.setIntoState(data),
      ...loadCountryConfig({
        apiUrl,
        country,
        successEvent: COUNTRY_CONFIG_LOADED,
      }),
      ...loadTranslations({
        locale,
        onFinishEventId: TRANSLATIONS_LOADED_WITHOUT_RENDER,
      }),
      ...analytics.trackClick('loggedin-user-confirmed', 'login-page-button'),
      ...effects.dispatchMany(events),
    };

    if (redirectAfterLogin) {
      effectDescriptions = {
        ...effectDescriptions,
        ...loadTranslations({
          locale,
          onFinishEventId: TRANSLATIONS_LOADED_WITHOUT_RENDER,
          redirectTo: redirectAfterLogin,
        }),
      };
    }

    if (isSelfServicePublisher) {
      effectDescriptions = {
        ...effectDescriptions,
        ...loadTranslations({
          locale,
          onFinishEventId: TRANSLATIONS_LOADED_WITHOUT_RENDER,
          redirectTo: '/properties',
        }),
      };
    }

    return effectDescriptions;
  },
  [
    environment(),
    state.get({
      redirectAfterLogin: 'redirectAfterLogin',
    }),
  ]
);

registerEventHandler(
  TRANSLATIONS_LOADED_WITHOUT_RENDER,
  (_, { translations, locale, redirectTo }) => ({
    ...state.set({ translations, locale, redirectAfterLogin: null }),
    ...navigateTo(redirectTo),
  })
);

registerEventHandler(LOG_IN_FAILED, () =>
  state.set({
    [LOGIN_HAS_FAILED]: true,
  })
);

registerEventHandler(FORGOT_PASSWORD_PAGE_MOUNTED, () =>
  state.set({
    'forgotPassword:mailSent': false,
  })
);

registerEventHandler(
  PASSWORD_RECOVERY_REQUESTED,
  ({ environment: { apiUrl } }, recoveryEmail) => ({
    ...http.post({
      url: `${apiUrl}/password/recovery`,
      body: JSON.stringify({ email: recoveryEmail }),
      successEvent: [PASSWORD_RECOVERY_REQUEST_SUCCESSFUL],
      errorEvent: [PASSWORD_RECOVERY_REQUEST_FAILED],
    }),
    ...state.set({ 'forgotPassword:failed': false }),
  }),
  [environment(), state.get({ recoveryEmail: 'forgotPassword:recoveryEmail' })]
);

registerEventHandler(PASSWORD_RECOVERY_REQUEST_SUCCESSFUL, () =>
  state.set({
    'forgotPassword:mailSent': true,
  })
);

registerEventHandler(PASSWORD_RECOVERY_REQUEST_FAILED, () =>
  state.set({
    'forgotPassword:failed': true,
  })
);

registerEventHandler(
  RESET_PASSWORD_REQUESTED,
  (
    { environment: { apiUrl }, queryParams: { token } },
    { password, confirmedPassword }
  ) => {
    if (password.length < 8) {
      return state.set({
        'resetPassword:error': INVALID_PASSWORD,
      });
    }
    if (password !== confirmedPassword) {
      return state.set({
        'resetPassword:error': MISMATCHED_PASSWORDS,
      });
    }
    return {
      ...state.set({ 'resetPassword:error': undefined }),
      ...http.put({
        url: `${apiUrl}/password/reset`,
        body: JSON.stringify({ token, password }),
        successEvent: PASSWORD_RESET,
        errorEvent: PASSWORD_RESET_FAILED,
      }),
    };
  },
  [environment(), queryParams.get(['token'])]
);

registerEventHandler(PASSWORD_RESET, () =>
  state.set({
    'resetPassword:reset': true,
  })
);

registerEventHandler(PASSWORD_RESET_FAILED, () =>
  state.set({ 'resetPassword:error': SERVER_ERROR })
);

registerEventHandler(GO_TO_LOGIN, () => navigateTo('/login'));
